import {  useContext, createContext,useEffect, useState } from 'react'

import { useWeb3, BN, multiCalls, utils, ZERO_ADDRESS, PROXY_ZERO_ADDRESS, MultiCallContract } from '../../web3'

import useToast from '../../hook/useToast'
import copy from 'copy-to-clipboard'

// import Usdt, {getUSDTAddress} from '../../contract/USDT'
import {
    BalanceList,
    Pair,
    PairsList,
    OracleList
} from '../../contract/contract'


import {
    // getAmountIn,
    getAmountOut
} from '../../utils/swap'
import initAsyncData from '../../hook/initAsyncData'

// 数据结构 第二层 只能是 非对象【函数也是对象】
// 避免初始化取值遇到 undefined 报错
// 需要对象的地方，使用 function 获取，避免初始化时候报错
const INIT = {
    gasName: '--',
    oracle: () => ({
        price: 0,
        pricePath: [],
        base: '',
        lpRs: [0,0]
    }),
    balance: {},
    getPrice: () => 0,
    getOutAmount: () => 0,
    getLp: () => ({
        reserves: [0, 0],
        totalSupply: 0
    })
}

export const Context = createContext(INIT)

// // 调整 getReserves 顺序
// function sortReserve(token0Addr, token1Addr, res0, res1) {
//     const isRe = token0Addr * 1 > token1Addr * 1
//     return isRe ? [res1, res0] : [res0, res1]
// }

async function init(account) {

    // pair 应覆盖 balance 里面的币种
    const balanceList = BalanceList()
    const oracleList = OracleList()
    const pairsList = PairsList()

    // console.log(pairsList, Pair(pairsList[0][0]).methods.getReserves, " pairsList")
    
    const multiCall = MultiCallContract()

    // console.log(lps, 'lps')
    const {DEFAULT, TOKENS, DEFAULT_PRICE, GAS_TOKEN} = oracleList
    const [gasToken, wGasToken] = GAS_TOKEN

    /// 组装 lp methods && balance methods
    const calls = await multiCalls({
        lpMap: pairsList.map(v => {
            return Pair(v[0]).methods.getReserves()
        }),
        balance: balanceList.map(v => v.erc20.methods.balanceOf(account)),
        eth: multiCall.methods.getEthBalance(account)
    })
   
    const lps = {
        [gasToken]: {}
    }
    calls.lpMap.forEach((res, i) => {
        const reserves0 = res[0]
        const reserves1 = res[1]
        

        const [, token0, token1] = pairsList[i]

        if (!lps[token0]) lps[token0] = {}
        if (!lps[token1]) lps[token1] = {}

        // console.log(reserves0, reserves1, token0, token1, 'reserves0, reserves1, token0, token1')

        lps[token0][token1] = [reserves0, reserves1];
        lps[token1][token0] = [reserves1, reserves0];

        if (token0 === wGasToken) {
            lps[gasToken][token1] = [reserves0, reserves1];
            lps[token1][gasToken] = [reserves1, reserves0];
        }
        if (token1 === wGasToken) {
            lps[gasToken][token0] = [reserves1, reserves0];
            lps[token0][gasToken] = [reserves0, reserves1];
        }
    })

    
    const getPrice = paths => {
        let inAmount = BN(1e6);
        const len = paths.length - 1;
        for(let i = 0; i < len; i++) {
            const tokenIn = paths[i]
            const tokenOut = paths[i + 1]

            // console.log(tokenIn, tokenOut, "resIn, resOut")
            // token in 是稳定价格
            // console.log(tokenIn, TOKENS[tokenIn])
            if ( typeof TOKENS[tokenIn] === 'number' ) {
                inAmount = inAmount.mul(TOKENS[tokenIn])
                break;
            }

            if (!lps[tokenIn] || !lps[tokenIn][tokenOut]) return DEFAULT_PRICE;
            const [resIn, resOut] = lps[tokenIn][tokenOut];
            
            inAmount = inAmount.mul(resOut).div(resIn)
        }
        return inAmount.div(1e6).toString()
    }

    // 输入 token0 数量，获取 token1 数量
    const getOutAmount = (inAmount, paths, slippage = 0.005) => {
        inAmount = BN(inAmount);
        // console.log(inAmount, "inAmount")
        const len = paths.length - 1;
        for(let i = 0; i < len; i++) {
            const tokenIn = paths[i]
            const tokenOut = paths[i + 1]

            
            
            // token In 不存在
            if (!lps[tokenIn]) {
                // token in 是稳定价格
                if ( typeof TOKENS[tokenIn] === 'number' ) {
                    inAmount = inAmount.mul(TOKENS[tokenIn])
                    break;
                }
                return DEFAULT_PRICE;
            } else if (!lps[tokenIn][tokenOut]) {
                if ( typeof TOKENS[tokenOut] === 'number' ) {
                    inAmount = inAmount.mul(TOKENS[tokenOut])
                    break;
                }
                return DEFAULT_PRICE;
            }
            const [resIn, resOut] = lps[tokenIn][tokenOut]
            inAmount = BN(getAmountOut(inAmount, resIn, resOut, 0.005))
        }
        return inAmount.mul(1 - slippage).toString()
    }

    // 获取计算价格
    const _oracle = {}
    /// 配合 getPrice 函数获取价格 getPrice([butch, meer, usdt], slippage)
    /// 配合 getOutAmount 函数获取输出数量 getOutAmount(inAmount, [butch, meer, usdt], slippage)
    
    const balance = {
        [gasToken]: BN(calls.eth).div(BN(10).pow(18)).toString()
    }
    // console.log(calls.balance,"calls.balance")
    calls.balance.forEach((bWeiStr, i) => {
        const {addr} = balanceList[i]
        const [, decimal, name] = addr
        balance[name] = BN(bWeiStr).div(BN(10).pow(decimal)).toString()
        // console.log(name, "Asdf")
        // ORACLE_LIST.TOKENS
        const pathToken = TOKENS[name];
        
        // 如果不存在，就拿 usdt
        const end = (!pathToken || typeof pathToken === 'number') ? [DEFAULT] : pathToken;
        
        const pathsIn = [name, ...end];
        // console.log(pathsIn[0], pathsIn[pathsIn.length - 1])
        // // 如果是手动设置的价格，lp 没有相应配置，lps undefined    
        let res0 = 0;
        let res1 = 0;
        let lpRes = lps[pathsIn[0]] || {}
        lpRes = lpRes[pathsIn[pathsIn.length - 1]]
        // console.log(lpRes, "lpRes")
        if (lpRes) {
            // const decimal0 = 
            res0 = BN(lpRes[0]).div(BN(10).pow(decimal)).toString();
            res1 = BN(lpRes[1]).div(BN(10).pow(decimal)).toString();
        }
        _oracle[name] = {
            price: getPrice(pathsIn),
            // getAmount: (inAmount, {paths = pathsIn, slippage} = {}) => {
            //     return getOutAmount(inAmount, paths, slippage)
            // },
            pricePath: pathsIn,
            base: end[end.length - 1],
            lpRs: [res0, res1]
        }
    })

    // console.log(
    //     lps, " lps"
    // )
    
    return {
        oracle: name => _oracle[name === gasToken ? wGasToken : name],
        balance,
        getPrice,
        getOutAmount,
        gasName: gasToken
    }
}


function useUserInfo() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const { open } = useToast()

    const copyAddress = () => {
        copy(data.gooseAddress)
        open('copied')
    }

    const blockNumber = getBlockNumber()
    useEffect(() => {
        if ( blockNumber > 0 && account !== PROXY_ZERO_ADDRESS) {
            initAsyncData(() => init(account), setData)
        }
    }, [account, blockNumber])
    return {
        ...data,
        copyAddress
    }
}

function UserProvider({ children }) {
    const data = useUserInfo()
    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export default UserProvider

export const useUser = () => useContext(Context)