
import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

// import Identicon from 'identicon.js'

import {
    Button,
    TextSM,
    Icon,
    // WingBlank
} from '../../components'


import copy from 'copy-to-clipboard'
import useToast from '../../hook/useToast'
import useDrawer from '../../hook/useDrawer'



import { useConnectWallet } from '../ConnectWallet'

import {useTransactionPendingCount} from '../../hook/useTransaction'

import TransactionDetail from '../TransactionDetail';


import { useWeb3, utils, updateTransaction } from '../../web3'
const { shortAddress } = utils


function Address() {
    const { t } = useTranslation();
    // const { open } = useToast()
    const { open: openNet } = useConnectWallet()
    const { account, unlock, getBlockNumber, chainId } = useWeb3()
    const short = useMemo(() => unlock ? shortAddress(account, 3) : t('no address'), [unlock, account, t])
    const {open: openTx,} = useDrawer(<TransactionDetail />, {closeButton: true})
    const pendingCount = useTransactionPendingCount()
    // const pendingCount = 1
    // const hash = useMemo(()=> 'data:image/png;base64,'+ new Identicon(account).toString(),[account])
    // const copyAddress = useCallback(() => {
    //     if (unlock) {
    //         copy(account)
    //         open(t('copied'))
    //     }
    // }, [account, unlock, t])
    // useEffect(() => {
    //     // 首次载入 强制 更新
    //     updateTransaction('0')
    // },[account, chainId])

    useEffect(() => {
        updateTransaction()
    },[getBlockNumber(), account, chainId])
    return (
        !unlock ?
            <AddressButton
                size='0'
                status='3'
                onClick={openNet}
            >
                <TextSM color='0'>{t("Connect Wallet")}</TextSM>
            </AddressButton>
            :
            pendingCount*1 > 0 ?
            <AddressButton
                size='3'
                onClick={openTx}
                // stytle={{
                //     paddingRight: '1rem !important'
                // }}
            >
                <TextSM color='0'>
                    Pending <Tips>{pendingCount}</Tips>
                </TextSM>
            </AddressButton>
            :
            <AddressButton
                size='3'
                onClick={openTx}
            >
                <TextSM color='0'>
                    {short}
                </TextSM>
            </AddressButton>
    )
}

export default Address

// const AddressImg = styled.img`
//     display: block;
//     border: 2px solid  #fff;
//     border-radius: 100px;
//     width: 24px;
// `

const AddressButton = styled(Button)`
    padding: .5rem 1.4rem !important;
    border-radius: 100px !important;
    background: rgb(189 196 208 / 11%);
    /* border: 1px solid #ff9a32; */
    &.MuiButton-root:hover {
        background: none;
    }
    /* color: #ff9a32; */
`

const Tips = styled.span`
    padding: 0px 4px;
    border-radius: 10px;
    background: #d75a2f;
    color: #fff;
`