
import {ERC20, newContract} from "../web3";
import {getConstants} from './constants' 
import IMu from './ABI/IMu.json'

import I_LP from './ABI/LP.json'
import I_STAKE from './ABI/IStake.json'
import I_NODE from './ABI/INode.json'
import I_PROTOCOL from './ABI/IProtocol.json'
import I_COMPENSATION from './ABI/ICompensation.json'
import I_GOVERN from './ABI/IGovern.json'
import I_GNFT from './ABI/IGNFT.json'
import I_PURCHASE_NFT from './ABI/IPurchaseNft.json'
import I_360 from './ABI/I360.json'
import I_SPRING from './ABI/ISpring.json'
import I_DITRIS from './ABI/IDtrisbute.json'


export const MUsdt = () => newContract(getConstants().USDT[0].toLocaleLowerCase(), IMu)

////// token useBalance //////
export const MeerAddr = () => getConstants().MEER

export const Tokens = () => {
    const {TOKEN_ALL} = getConstants()
    const ti = {}
    Object.keys(TOKEN_ALL).map(k => {
        ti[k] = ERC20(TOKEN_ALL[k][0])
        return k
    })
    return ti
}

export const TokenAddr = () => getConstants().TOKEN_ALL
export const WMeerAddr = () => getConstants().WMEER

export const ChooseList = () => getConstants().CHOOSE_LIST

export const Pair = address => newContract(address, I_LP)

export const BalanceList = () => {
    const {BALANCE_LIST} = getConstants()
    return BALANCE_LIST.map(v => (
        {
            addr: v,
            erc20: ERC20(v[0]) 
        }
    ))
}

export const PairsList = () => getConstants().LP_LIST;

export const OracleList = () => getConstants().ORACLE_LIST;

////// token end //////

///// stake /////
export const Stake = () => newContract(getConstants().STAKE, I_STAKE)
export const Node1 = () => newContract(getConstants().NODE1, I_NODE)
export const Node2 = () => newContract(getConstants().NODE2, I_NODE)
export const Node3 = () => newContract(getConstants().NODE3, I_NODE)


///// protocol /////
export const Protocol = () => newContract(getConstants().PROTOCOL, I_PROTOCOL)
export const ProtocolV2 = () => newContract(getConstants().PROTOCOL_V2, I_PROTOCOL)

///// compensation /////
export const Compensation = () => newContract(getConstants().COMPENSATION, I_COMPENSATION)
export const CompensationV1 = () => newContract(getConstants().COMPENSATION_V1, I_COMPENSATION)


// GOVERN
export const Govern = () => newContract(getConstants().GOVERN, I_GOVERN)

// GNFT
export const Gnft = () => newContract(getConstants().GNFT, I_GNFT)
export const Nft360 = () => ERC20(getConstants().NFT360)
export const PurchaseNFT = () => newContract(getConstants().PURCHASE_NFT, I_PURCHASE_NFT)

export const I360 = () => newContract(getConstants().I_360, I_360)

export const Spring = () => newContract(getConstants().SPRING, I_SPRING)

export const Distribute = () => newContract(getConstants().DISTRIBUTE, I_DITRIS)