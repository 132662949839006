import {getConstants} from './constants' 
import {utils, BN, MAX_UINT} from "../web3"
const { shortAddress } = utils

// 各链标准配置
export const ChainConfig = () => {
    const {SCAN_PATH, SCAN_PATH_ADDRESS, SCAN_PATH_TX} = getConstants()
    return {
        addressPath: (address) => `${SCAN_PATH}${SCAN_PATH_ADDRESS}${address}`,
        txPath: (tx) => `${SCAN_PATH}${SCAN_PATH_TX}${tx}`,
        scanPath: SCAN_PATH,
    }
}


// 交易备注配置，根据 contract method name 显示在 transaction 详情页
// 默认显示 和 其他规则【根据地址或参数名不同进行修改】
// 未配置情况下自动显示 method name
let _TX_SUMMARY = null
export function TxSummaryInit() {
    const {TOKEN_ALL} = getConstants()
    const tokenAddressMap = {}
    Object.keys(TOKEN_ALL).forEach(k => {
        tokenAddressMap[TOKEN_ALL[k][0]] = TOKEN_ALL[k]
    })
    return {
        claimfor: () => `Claim`,
        approve: summary => {
            if (summary.cont) return summary.cont
            const coin = tokenAddressMap[summary.to]
            const [sender, amount] = summary.params
            let tokenName;
            let approveAmount;
            let toSender = shortAddress(sender, 3)
            if (coin) {
                tokenName = coin[2]
                approveAmount = BN(amount).div(10**coin[1]).dp(3,1).toString(10)
            } else {
                tokenName = shortAddress(summary.to, 3)
                approveAmount = BN(amount).div(10**18).dp(3,1).toString(10)
            }
            if (MAX_UINT === amount) approveAmount = '∞'
            if ( approveAmount * 1 === 0 ) return `Clear Approve ${tokenName} to ${toSender}`
            return `Approve ${tokenName} ${approveAmount} to ${toSender}`
        },
        unstakelp: () => {
            return `Unstake LP`
        },
        claim: () => `Claim`,
        register: () => `Activate`,
        stakeusdt: (summary) => {
            const [stakeWei,] = summary.params
            return `Stake USDT ${BN(stakeWei).div(10**TOKEN_ALL['USDT'][1]).dp(3,1).toString(10)} to ${shortAddress(summary.to, 3)}`
        },
        claimnode: () => `Claim Node Earns`,
        signterm: () => `Signed Risk Agreement`,
        swapexacttokensfortokenssupportingfeeontransfertokens: (summary) => {
            if (summary.cont) return summary.cont
            const [fromWei,, tokenPath] = summary.params
            const [tokenInAddress, tokenOutAddress] = tokenPath
            let tokenInName
            let inDesimal = 18
            if (tokenAddressMap[tokenInAddress]) {
                tokenInName = tokenAddressMap[tokenInAddress][2]
                inDesimal = tokenAddressMap[tokenInAddress][1]
            } else {
                tokenInName = shortAddress(tokenInAddress, 3)
            }
            let tokenOutName;
            if (tokenAddressMap[tokenOutAddress]) {
                tokenOutName = tokenAddressMap[tokenOutAddress][2]
            } else {
                tokenOutName = shortAddress(tokenOutAddress, 3)
            }
            return `Swap ${BN(fromWei).div(10**inDesimal).dp(3,1).toString(10)} ${tokenInName} for ${tokenOutName}`
        },
    }
}

// 切换网络初始化 配置
export const SetSummaryConfig = () => _TX_SUMMARY = TxSummaryInit()

// 获取全局配置
export const TxSummary = () => _TX_SUMMARY