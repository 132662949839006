import { useEffect } from 'react';
// import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { wFormatInt } from 'web3-sam/dist/BigNumber';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,
    NoData,
    Tips
} from '../../components'

// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'

import {
    useClaimSpring,
    useSpringData,
    useSpringStake,
    useIsSigned
} from '../../hook/pages/useSpring'
// import {
//     useCompensation,
//     useRegisterMet,
//     useClaim,
//     useSettleHistory,
//     useRegisterMetPro,
//     useRegisterMetMintPro,
//     useClaimPro
// } from '../../hook/pages/useCompensation'
import {utils} from 'web3-sam'
import Approve from '../../contractComponents/Approve';

import Lucky, {useLuck} from '../Lucky'
import Protocol from './Protocol'

import {getUrlParams} from '../../utils'

const { shortAddress } = utils

const YIELD = 1.5
export function SpringPoolHome () {
    const sData = useSpringData()
    const claimButton = useClaimSpring()
    const history = useHistory()
    return (
        <Card decorate='1'>
            <TextMD>Spring Pool</TextMD>
            <br />
            <TextSM color='2'>Stake MDAO to earn MDAO, Monthly income {YIELD}% </TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>Available MDAO</TextSM>
                    <br />
                    <TextMD>{sData.claim}</TextMD>
                </div>
                {/* <Button size='0' {...claimButton} /> */}
                <div>
                    <Button size='0' {...claimButton} />
                    <Button style={{marginLeft: '6px'}} size='0' status='1' onClick={() => history.push('/spring')}>Stake</Button>
                </div>
            </FlexBlock>
        </Card>
    )
}

export function SpringPoolPage () {
    const isSigned = useIsSigned()
    if ( isSigned === null ) return <Container><Card align='center'><TextMD>Loading...</TextMD></Card></Container>
    if ( isSigned === false ) return <Protocol />
    return <Spring />
}

 function Spring () {
    const bal = useBalance()
    const sData = useSpringData()
    const {
        start,
        stop,
        play
    } = useLuck()
    const {
        amountMdao,
        referrer,
        stakeButton
    } = useSpringStake({start, stop})
    const claimButton = useClaimSpring()

    
    useEffect(() => {
        const {r} = getUrlParams()
        // console.log(t,'t')
        if (r) {
            referrer.onChange(r)
        }
    },[])
    // console.log(sData)
    const stakedValue = bal.getOutAmount(amountMdao.value || 0,["MDAO","USDT"])
    return (
        <Container>
            <Card decorate='1'>
                <TextMD>Spring Pool</TextMD>
                <br />
                <TextSM color='2'>Stake MDAO to earn MDAO, Monthly income {YIELD}% </TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div onClick={sData.copyLink}>
                        <TextSM color='2'>Available MDAO <TextLinear size='12'> Copy Share Link</TextLinear></TextSM>
                        <br />
                        <TextMD>{sData.claim}</TextMD>
                    </div>
                    <Button size='0' {...claimButton} />
                    {/* <Button size='0'>Claim</Button> */}
                </FlexBlock>
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM color='2'>Staked MDAO</TextSM>
                    <TextSM>{wFormatInt(sData.userStakedMDAO)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>Staked Value</TextSM>
                    <TextSM>{wFormatInt(sData.userStakedValue)}</TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM color='2' >Balance <TextLinear size='0'>{wFormatInt(bal.balance.MDAO)}</TextLinear> MDAO</TextSM>
                <WhiteSpace style={{height:'6px'}}/>
                <Input
                    input={amountMdao}
                />
                <WhiteSpace style={{height:'6px'}}/>
                <TextSM color='2'>Expect Stake Value <TextSM>{wFormatInt(stakedValue)}</TextSM></TextSM>
                {   
                    !sData.isBinded &&
                    <>
                        <WhiteSpace />
                        <TextSM color='2' >Referral Address</TextSM>
                        <WhiteSpace style={{height:'6px'}}/>
                        <Input
                            input={referrer}

                        />
                    </>
                }
                <WhiteSpace />
                <Tooltip
                    title={
                        <div style={{width: '260px'}}>
                            <FlexBlock flex>
                                <TextSM style={{flex: 1}}>User</TextSM>
                                <TextSM style={{flex: 1, textAlign:'right'}}>Staked</TextSM>
                            </FlexBlock>
                            <WhiteSpace />
                            {
                                sData.ref.map((v, i) => (
                                    <FlexBlock flex key={v.addr}>
                                        <TextSM style={{flex: 1}}>{shortAddress(v.addr)}</TextSM>
                                        <TextSM style={{flex: 1, textAlign:'right'}}>{wFormatInt(v.amount)}</TextSM>
                                    </FlexBlock>
                                ))
                            }
                        </div>
                        
                    }
                    placement="top"
                    arrow
                >
                    <TextSM color='2'>My Locked Share <TextSM>{sData.shareLocked}</TextSM> MDAO <Icon size='12' type='icon-prompt' /></TextSM>
                </Tooltip>
                <WhiteSpace />
                <Approve w='100' status='1' {...stakeButton}/>
            </Card>
            <WhiteSpace />
            <Card decorate='1'>
                <TextM>Staked List</TextM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                {
                    sData.position.map(v => (
                        <div key={v.id}>
                            <TextM># {v.id + 1}</TextM>
                            <FlexBlock flex>
                                <TextSM color='2'>Staked MDAO</TextSM>
                                <TextSM>{wFormatInt(v.staked)}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Staked Value</TextSM>
                                <TextSM>{wFormatInt(v.stakeValue)}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Earned MDAO</TextSM>
                                <TextSM>{(v.claimedMDAO)}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Unlock</TextSM>
                                <TextSM color='2'><TextSM>{v.days}</TextSM> D</TextSM>
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                            <WhiteSpace />
                        </div>
                    ))
                }
            </Card>
            <Lucky play={play}/>
        </Container>
    )
}
