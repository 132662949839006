

import { useEffect } from 'react'
import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,

} from '../../components'

// import StakeDialog from '../StakeDialog'
import Approve from '../../contractComponents/Approve'

import useBalance from '../../hook/pages/useBalance'

import {useStake, useIsSigned} from '../../hook/pages/useStake'



import { wFormatInt, BN } from '../../web3'

import Protocol from './Protocol'

import {getUrlParams} from '../../utils'


function StakeingDialog({}) {
    const bal = useBalance()
    const {
        parentAddress,
        stakeAmount,
        approve,
        hashParent,
        disabledMin,
        approveMSC,
        disabledMSCMix
    } = useStake()
    // console.log(disabledMin, approve)
    useEffect(() => {
        const {r} = getUrlParams()
        // console.log(t,'t')
        if (r) {
            parentAddress.onChange(r)
        }
    },[])
    const getMax = () => stakeAmount.onChange(bal.balance.USDT)
    return (
        <Card decorate='1'>
            <TextMD>Stake Lp</TextMD>
            <br />
            <TextSM color='2'> Pledge USDT minnig MDAO, APR 120%.</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <TextSM color='2'>Balance: <TextLinear size='0'>{wFormatInt(bal.balance.USDT)}</TextLinear> USDT · <TextLinear size='0'>{wFormatInt(bal.balance.MSC)}</TextLinear> MSC</TextSM>
            <Input
                input={{
                    ...stakeAmount,
                    placeholder: '0.00',
                }}
                after={
                    <Button size='0' onClick={getMax}>Max</Button>
                }
            />
            {
                !hashParent &&
                <>
                    <WhiteSpace />
                    <TextSM color='2'>Recommended</TextSM>
                    <Input
                        input={parentAddress}
                    />
                </>
            }
            <TextSM>Minimum MSC {wFormatInt((stakeAmount.value * 0.111111).toFixed(3))}</TextSM>
            <WhiteSpace />
            {disabledMin && stakeAmount.value !== '' && <TextSM style={{color: '#c45454'}}>* Stake Value must {">="} 100 USDT</TextSM>}
            {/* <FlexBlock flex>
                <Approve w='49' {...approve} />
                <Approve w='49' status='1' {...approveMSC} />
            </FlexBlock> */}
            <Approve w='100' {...approve} />
        </Card>
    )
}


function StakeDialog() {
    const isSigned = useIsSigned()
    // console.log(isSigned)
    return isSigned === null ? <Card align='center'><TextMD>Loading...</TextMD></Card> : !isSigned ? <Protocol /> : <StakeingDialog />
    // return <Card align='center'><TextMD>Loading...</TextMD></Card>
}
export default StakeDialog