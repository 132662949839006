

const getRingPercent = (percent, r) => {
    let perimeter = Math.PI * 2 * r; //周长
    // 前面实线 后面虚线
    const solid = (percent / 100) * perimeter

    console.log(percent,'percent')
    return {
        strokeDasharray: solid + ' ' + (perimeter - solid),
        strokeDashoffset: perimeter / 2
    }
}

function Circle({size = 100, per = 100, style, ...other}) {
    // size = size * 0.8
    const strokeWidth = size / 220 * 16
    const r = size / 2 - 2 * strokeWidth
    const c = size / 2

    const { strokeDasharray, strokeDashoffset } = getRingPercent(per, r)

    // console.log({ strokeDasharray, strokeDashoffset })
    
    return (
        <div
            {...other}
            style={{
                width: "100%",
                textAlign: 'center',
                ...style
            }}
        >
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <defs>
                    <linearGradient id='svg_1' x1='0%' y1='0%' x2='100%' y2='64.9%'>
                        <stop offset='0%' stop-color='#9bce31' />
                        {/* <stop offset='50%' stop-color='#ef9383' /> */}
                        <stop offset='100%' stop-color='#a4e915' />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id='svg_2' x1='0%' y1='0%' x2='100%' y2='64.9%'>
                        <stop offset='0%' stop-color='#ffd304' />
                        {/* <stop offset='50%' stop-color='#ef9383' /> */}
                        <stop offset='100%' stop-color='#ff7263' />
                    </linearGradient>
                </defs>
                <defs>
                    <filter id="f1" width="200%" height="200%">
                        <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                <circle
                    cx={c}
                    cy={c}
                    r={r}
                    strokeWidth={strokeWidth}
                    stroke='#706ef4'
                    fill='none'
                ></circle>
                <circle
                    cx={c}
                    cy={c}
                    r={r}
                    strokeWidth={strokeWidth * 22 / 14}
                    stroke='url(#svg_1)'
                    fill='none'
                    // stroke-linecap='round'
                    // transform='rotate(45deg)'
                    // {...getRingPercent(per, r)}
                    {...{strokeDasharray, strokeDashoffset}}
                ></circle>
            </svg>
        </div>
    )
}


export default Circle



const getRingList = (percentArr, r) => {
    const perimeter = Math.PI * 2 * r; //周长
    const solidArr = []
    let preOffset = perimeter / 4;
    percentArr.map(v => {
        // 实线长度
        const solid = v / 100 * perimeter
        // 虚线长度
        const dotted = perimeter - solid
        // console.log(preOffset, "preOffset")
        solidArr.push({
            strokeDashoffset: preOffset,
            strokeDasharray: `${solid} ${dotted}`
        })
        preOffset += dotted
    })
    return solidArr
}
export function CircleArr({size = 100, per = [100], color=[["#9bce31",'#a4e915']], style, ...other}) {
    // size = size * 0.8
    const strokeWidth = size / 220 * 16
    const r = size / 2 - 2 * strokeWidth
    const c = size / 2

    const ring = getRingList(per, r)
    return (
        <div
            {...other}
            style={{
                width: "100%",
                textAlign: 'center',
                ...style
            }}
        >
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                {
                    color.map((v,i) => (
                        <defs key={i}>
                            <linearGradient id={'svg_'+i} x1='0%' y1='0%' x2='100%' y2='64.9%'>
                                <stop offset='0%' stop-color={v[0]} />
                                {/* <stop offset='50%' stop-color='#ef9383' /> */}
                                <stop offset='100%' stop-color={v[1]} />
                            </linearGradient>
                        </defs>
                    ))
                }
                <defs>
                    <filter id="f1" width="200%" height="200%">
                        <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                <circle
                    cx={c}
                    cy={c}
                    r={r}
                    strokeWidth={strokeWidth}
                    stroke='rgba(255,255,255,0.2)'
                    fill='none'
                ></circle>
                {
                    ring.map((v, i) => (
                        <circle
                            key={i}
                            cx={c}
                            cy={c}
                            r={r}
                            strokeWidth={strokeWidth * 22 / 14}
                            stroke={`url(#svg_${i})`}
                            fill='none'
                            // stroke-linecap='round'
                            // transform='rotate(45deg)'
                            // {...getRingPercent(per, r)}
                            {...v}
                        ></circle>
                    ))
                }
                
            </svg>
        </div>
    )
}