// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    TokenAddr,
    Spring,
    ProtocolV2
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useCopy from '../useCopy'

import useSendButton from "../useSendButton"
import useButton from "./useButton"

const INIT = {
    referrer: ZERO_ADDRESS,
    isBinded: false,
    claim: 0,
    userStakedMDAO: 0,
    userStakedValue: 0,
    shareLocked: 0,
    position: [],
    ref: []
}

async function init(account) {
    const spring = Spring()

    // console.log(
    //     account,
    //     await spring.methods.referrer(account).call(),
    // )
    const calls = await multiCalls({
        claim: spring.methods.claimFor(account),
        referrer: spring.methods.referrer(account),
        userInfo: spring.methods.userInfo(account),
        ref: spring.methods.referralsBalance(account)
    })
    // console.log(calls.userInfo.totalEarnMDaoByTime)
    const shareEarn = BN(calls.claim.share).dividedBy(1e18).toString(10)
    const posEarn = BN(calls.claim.pos).dividedBy(1e18).toString(10)
    const claim = BN(shareEarn).plus(posEarn).dp(6).toString(10)

    let userStakedMDAO = BN(0)
    let userStakedValue = BN(0)
    const position = []

    const now = Date.now() / 1000
    const day = 24 * 60 * 60
    calls.claim.pList.forEach((v, i) => {
        const stakeMDao = v.stakeMDao
        userStakedMDAO = userStakedMDAO.plus(stakeMDao)
        const stakeValue = v.stakeValue
        if ( stakeMDao !== '0' ) {
            userStakedValue = userStakedValue.plus(stakeValue)
        } 
        const claimedMDao = v.claimedMDao
        const unlockDay = Math.max(0, Math.floor((v.lockTime * 1 + v.startTime * 1 - now) * 1000 / day / 100) / 10)
        position.push({
            id: i,
            staked: BN(stakeMDao).dividedBy(1e18).toString(10),
            claimedMDAO: BN(claimedMDao).dividedBy(1e18).dp(6,1).toString(10),
            stakeValue: BN(stakeValue).dividedBy(1e18).toString(10),
            days: unlockDay
        })
    })

    userStakedMDAO = userStakedMDAO.dividedBy(1e18).toString(10)
    userStakedValue = userStakedValue.dividedBy(1e18).toString(10)

    const shareLocked = BN(calls.userInfo.totalEarnedMDaoWithoutTime).sub(calls.userInfo.claimedMDao).dividedBy(1e18).dp(6,1).toString(10)

    const ref = []
    calls.ref[0].forEach((v, i) => {
        ref.push({
            addr: v,
            amount: BN(calls.ref[1][i]).dividedBy(1e18).toString(10)
        })
    })
    return {
        referrer: calls.referrer,
        isBinded: calls.referrer !== ZERO_ADDRESS,
        claim,
        position: position.sort((a, b) => b.id - a.id),
        userStakedMDAO,
        userStakedValue,
        shareLocked,
        ref
    }
}

export function useSpringData() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => init(account), setData)
    },[account, blockNubmer])
    const copy = useCopy()
    const copyLink = () => {
        // copy(data.link)
        copy(
            window.location.origin + '/#/spring?r=' + account
        )
    }
    return {
        ...data,
        copyLink
    }
}

export function useSpringStake({start = () => {}, stop = () => {}} = {}) {

    const amountMdao = useInput('', {type: 'number',placeholder: 'Min 50 USDT'})
    const referrer = useInput('', {type: 'text',placeholder: 'Referral Address'})


    const {
        sender,
        coins
    } = useMemo(() => {
        const sender = Spring()._address
        const coins = [[...TokenAddr().MDAO, 1000000, true]]
        return {
            sender,
            coins
        }
    },[])

    const stakeButton = useButton('Stake', {
        approve: {
            sender,
            coins
        },
        send() {
            // start()
            // setTimeout(() => {
            //     stop(90)
            // }, 1000)
            const node = Spring()
            const amountMdaoWei = BN(amountMdao.value).multipliedBy(1e18).dp(0,1).toString(10)
            const referrerAddr = referrer.value === '' ? ZERO_ADDRESS : referrer.value
            return node.methods.stake(amountMdaoWei, referrerAddr)
        },
        pending() {
            start()
        },
        confirm(res) {
            if ( res.events ) {
                stop(Math.floor(res.events.Seed.returnValues.stakeTime / 86400))
            } else {
                stop(90)
            }
        }
    })

    return {
        amountMdao,
        referrer,
        stakeButton
    }
}

export function useClaimSpring() {
    const {account} = useWeb3()
    return useButton('Claim', {
        send() {
            const node = Spring()
            return node.methods.claimFor(account)
        }
    })
}

export function useIsSigned() {
    const {getBlockNumber, account} = useWeb3()
    const [data, setData] = useState(null)

    useEffect(() => {
        if (data === true) return
        initAsyncData(async () => {
            const protocol = ProtocolV2()
            const isSigned = await protocol.methods.isSignOf(account).call()
            // console.log('isSigned', isSigned, protocol._address)
            return isSigned
        }, setData)
    },[getBlockNumber(), account])

    return data
}

export function useSign() {

    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Agree And Sign')


    const signFun = async () => {
        const protocol = ProtocolV2()
        const web3 = initWeb3()
        // 签名
        loading("Sign...")
        const hashedMessage = "0xa6053b8a64e97b8b9ab92e058e5f5faac5f5fce4dd1ba60b20dffa950b26b433"
        let signature;
        try {
            signature = await web3.currentProvider.request({
                method: "personal_sign",
                params: [hashedMessage, account],
            });
        } catch (error) {
            init()
            fail('Sign fail')
        }

        if ( signature ) {
            const r = signature.slice(0, 66);
            const s = "0x" + signature.slice(66, 130);
            const v = parseInt(signature.slice(130, 132), 16);
            loading("Register...")
            SendLocalOn(
                protocol.methods.signTerm(hashedMessage, v, r, s),
                {
                    signDone: init,
                    cont: 'Agreement Spring Pool',
                    cancel() {
                        fail('Agreement Cancel')
                    },
                    fail(err) {
                        txError(err.message)
                    },
                    confirm () {
                        successful('Agreement successful')
                    },
                    confirmDone: init
                }
            )
        }
    }

    return {
        button,
        signFun
    }
}