// import { memo } from 'react';

// import { useHistory } from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip
} from '../../components'


import {
    useDistribute,
    useDistributeSend
} from '../../hook/pages/useTools'

import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'



function Distribute() {
    const {balance, address} = useDistribute()
    const button = useDistributeSend()
    const copy = useCopy()
    return (
        <Container>
            <Card>
                <TextM >Balance</TextM>
                <br />
                <TextMD >{wFormatInt(balance)}</TextMD>
                <br />
                <TextSM onClick={() => copy(address)}>{address} </TextSM>
                <WhiteSpace />
                <Button w='100' {...button} />
            </Card>
        </Container>
    )
}

export default Distribute