import {useRef, useEffect, useState} from 'react'

import styled from 'styled-components'
import { SlotMachine } from '@lucky-canvas/react'

import {
    Card,
    TextMD,
    TextGL,
    WhiteSpace,
    Dialog
} from '../../components'

// import useDialog from '../../hook/useDialog'


const config = {
    blocks: [
        {borderRadius: '10px', pending: '4px'}
    ],
    // blocks: [
    //     {
    //         // padding: '20px',
    //         imgs: [{
    //           src: './luckyBlock.png',
    //           width: '100%',
    //           top: '0%',
    //           rotate: true
    //         }]
    //     }
    // ],
    slots: [
        { speed: 1 },
        { speed: 2 },
    ],
    prizes: new Array(10).fill(1).map((_, i) => ({ background: '#faf9f6', fonts: [{ text: i , lineHeight: '44px', fontWeight: 600, fontSize: '42px' }] })),
    // [
        
    //     // { fonts: [{ text: '0', top: '45%' }] },
    //     // { fonts: [{ text: '9', top: '15%' }] },
    // ],
    defaultStyle: {
        borderRadius: '15px',
        // background: '#bac5ee',
        // fontSize: '32px',
        fontColor: '#302a42',
        // fontWeight: '800',
    },
    defaultConfig: {
        rowSpacing: '10px',
        colSpacing: '4px'
    }
}

export default function Lucky ({play}) {
    const myLucky = useRef()
    const [opened, setOpen] = useState(false)
    useEffect(() => {
        if ( play === true ) {
            setOpen(true)
            myLucky.current.play()
        }
        else if ( typeof play === 'number' ) {
            myLucky.current.stop((play + '').split('').map(v => parseInt(v)))
            // myLucky.current.stop([9,0])
            setTimeout(() => {
                setOpen(false)
            },4000)
        }
        else {
            
            if ( myLucky.current ) {
                myLucky.current.init()
            }
        }
    }, [play])
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            scroll='body'
            keepMounted
            onClose={setOpen}
            aria-labelledby="simple-dialog-title"
            open={opened}
        >
            <Card align='center' decorate='1'>
                <WhiteSpace />
                <TextGL>Spring Lucky</TextGL>
                <WhiteSpace />
                <LuckyBlock>
                    <LuckyIntoBlock>
                        <SlotMachine width="116px" height="64px" ref={myLucky} {...config}></SlotMachine>
                    </LuckyIntoBlock>
                </LuckyBlock>
                {/* <WhiteSpace />
                <TextMD color='2'>The yield 0.43% / Day</TextMD> */}
                <WhiteSpace />
            </Card>
        </Dialog>
    )
}

export function useLuck() {
    const [play, setPlay] = useState(false)
    return {
        start: () => setPlay(true),
        stop: n => {
            setPlay(n)
        },
        play,
    }
}


const LuckyBlock = styled.div`
    position: relative;
    background-image: url(./luckyBlock.png);
    background-size: 100% 100%;
    width: 240px;
    height: 113px;
    margin: 0 auto;
`

const LuckyIntoBlock = styled.div`
    position: absolute;
    top: 50%;
    left: 17px;
    margin-top: -35px;
    border-radius: 10px;
    overflow: hidden;
`