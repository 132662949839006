

// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    Distribute,
    MUsdt,
    Tokens
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS } from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"

import useCopy from '../useCopy'

// imp
const  INIT_DISTRIBUTION = {
    balance: 0,
    address: ZERO_ADDRESS
}

async function initDistribution() {
    const tokens = Tokens()
    const distribute = Distribute()
    const calls = await multiCalls({
        balance: tokens.USDT.methods.balanceOf(distribute._address)
    })

    return {
        balance: BN(calls.balance).dividedBy(1e18).toString(10),
        address: distribute._address
    }
}

export function useDistribute() {
    const {getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT_DISTRIBUTION)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => initDistribution(), setData)
    },[blockNubmer])
    return {
        ...data
    }
}

export function useDistributeSend() {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Send')


    const onClick = async () => {
        // 签名
        loading("Pending")
        const distribute = Distribute()
        const {confirmation} = SendOn(
            distribute.methods.bistribute(),
            {seed: 2}
        )
        const [err, status] = await confirmation()
        init()
        if (status === -1) {
            txError(err.message)
            return
        }
        if (err !== null) {
            fail('Send fail')
            return
        }
        successful('Send successful')
    }

    return {
        ...button,
        onClick
    }
}
