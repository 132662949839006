import copyFun  from 'copy-to-clipboard'
import useToast from './useToast'

function useCopy() {
    const { open } = useToast()
    const copy = content => {
        copyFun(content)
        open('copied')
    }
    return copy
}

export default useCopy