// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    TokenAddr,
    Stake,
    Node1,
    Node2,
    Node3,
    Pair,
    PairsList,
    Protocol
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"

import useCopy from '../useCopy'

// import {getUrlParams, debounce} from "../../utils"


/// claimValue 可以 claim 的 价值
/// claimMDAO 可以 claim 的 mdao
/// claimUSDT 可以 claim 的 usdt
/// nodeSlip 节点收益 []
/// totalShareValue 分享总可获得的奖励价值
/// rewardedShareValue 已经分享获得的奖励价值
/// totalStaked 用户的总质押
/// postion 仓位
/// totalPostionValue 总已释放价值

const INIT = {
    isSigned: false,
    claimValue: 0,
    claimMDAO: 0,
    claimUSDT: 0,
    claimNodeMDAO: 0,
    parentAddr: ZERO_ADDRESS,
    hasParent: false,
    nodeSlip: () => [{
        claim: 0,
        balanceOf: 0,
        totalStake: 0
    }],
    totalShareValue: 0,
    rewardedShareValue: 0,
    totalStaked: 0,
    postion: [],
    totalPostionValue: 0,
    getLpValue: () => 0,
    child: [],
    copyLink: () => { },
    nodeProportion: [0,0,0]
}

// // 国库收益：平台管理費 5%
// uint public totalTreasuryUsdt;
// // 将池 大小 15%的U進入奬池
// uint public prizePool;
// // 盈余可提现 提取收益时 应 扣除 待结算部分
// uint public surplus;
// // 可提现 goose
// uint public surplusGoose;
// // 回购 goose 17.5% 回购G
// uint public repoBalance;
async function init(account) {
    // console.log(account, "account")
    const stake = Stake()
    const node1 = Node1()
    const node2 = Node2()
    const node3 = Node3()
    const pList = PairsList()

    const lp = pList.find(v => v[1] === 'MDAO' || v[2] === 'MDAO')
    const pair = Pair(lp[0])
    // console.log(lp[0], 'pair')
    const {USDT, MDAO} = TokenAddr()

    // const protocol = Protocol()
    // console.log(
    //     await stake.methods.blockTimeMulE8().call(),
    //     "12312312312"
    // )

    // function con(method) {
    //     console.log("method ", method._method.name)
    //     return method.call()
    // }

    // console.log(

    //     node3,
    // )
    // console.log(
    //     await con(
    //         node3.methods.withdrawFor(account)
    //     )
    // )

    

    const calls = await multiCalls({
        // isSigned: protocol.methods.isSignOf(account),
        totalLp: pair.methods.totalSupply(),
        lpReserves: pair.methods.getReserves(),
        timeE8: stake.methods.blockTimeMulE8(),
        claim: stake.methods.claim(account),
        positionFor: stake.methods.positionFor(account),
        userInfo: stake.methods.userInfo(account),
        parentAddr: stake.methods.upAddress(account),
        children: stake.methods.getDownFor(account),
        node: [
            {
                claim: node1.methods.withdrawFor(account),
                balance: node1.methods.stakeBalance(account),
                totalStake: node1.methods.totalStake(),
            },
            {
                claim: node2.methods.withdrawFor(account),
                balance: node2.methods.stakeBalance(account),
                totalStake: node2.methods.totalStake(),
            },
            {
                claim: node3.methods.withdrawFor(account),
                balance: node3.methods.stakeBalance(account),
                totalStake: node3.methods.totalStake(),
            }
        ]
    })

    // console.log("asgsdgsd")

    let resMDAO = calls.lpReserves[0]
    let resUSDT = calls.lpReserves[1]
    if (  USDT[0] * 1 < MDAO[0] * 1 ) {
        [resUSDT, resMDAO] = [resMDAO, resUSDT]
    }
    
    const getLpValue = lpAmount => {
        const mdaoValue = BN(lpAmount).mul(resMDAO).div(calls.totalLp)
        const usdtValue = BN(lpAmount).mul(resUSDT).div(calls.totalLp)
        return  {
            value: usdtValue.mul(2).toString(10),
            usdt: usdtValue.toString(10),
            mdao: mdaoValue.toString(10)
        }
    }
    
    // _userInfo.totalShareUSDT - _userInfo.rewardedShareUSDT * 1e8 / _timeMultiE8 - _userInfo.rewardedUSDT;
    const totalShareValue = BN(calls.userInfo.totalShareUSDT).div(1e18).toString(10)
    // const rewardedShareValue = BN(calls.userInfo.totalShareUSDT).sub(BN(calls.userInfo.rewardedShareUSDT).mul(1e8).div(calls.timeE8)).sub(calls.userInfo.rewardedUSDT).toString(10)
    const rewardedShareValue = BN(calls.userInfo.rewardedUSDT).div(1e18).toString(10)
    
    // console.log(
    //     calls.userInfo
    // )
    const claimMDAO = BN(calls.claim._mdaoAmount).div(1e18).dp(6,1).toString(10)
    const claimUSDT = BN(calls.claim._usdtAmount).div(1e18).dp(6,1).toString(10)

    const totalStaked = BN(calls.userInfo.totalStakedUSDT).div(1e18).toString(10)

    let totalPostionValue = BN(0)
    const postion = calls.positionFor.map((item, id) => {
        const {lpStaked, rewardedMDAO, rewardedUSDT, startTime, totalRewardedUSDT} = item
        const totalSated = BN(totalRewardedUSDT).mul(2.5).div(1e18).toString(10)
        const rewardedValue = BN(rewardedUSDT).div(1e18)
        totalPostionValue = totalPostionValue.add(rewardedValue)
        return {
            id,
            lpStaked: BN(lpStaked).div(1e18).toString(10),
            lpDetail: getLpValue(BN(lpStaked).div(1e18)),
            totalSated,
            rewardedValue: rewardedValue.dp(6,1).toString(10),
            rewardedMDAO: BN(rewardedMDAO).div(1e18).toString(10),
            unlockSeconds: startTime * 1 + 120 * 24 * 60 * 60, 
            lockSeconds: startTime
        }
    })

    totalPostionValue = totalPostionValue.toString(10)

    const claimValue = BN(calls.claim._valueAmount).div(1e18).dp(6,1).toString(10)

    let claimNodeMDAO = BN(0)
    const _nodeList = []
    calls.node.forEach((item, id) => {
        const {claim, balance, totalStake} = item
        claimNodeMDAO = claimNodeMDAO.add(BN(claim))
        _nodeList.push({
            id,
            balance: BN(balance).div(1e18).toString(10),
            totalStake: BN(totalStake).div(1e18).toString(10),
            claim: BN(claim).div(1e18).dp(6,1).toString(10)
        })
    })

    claimNodeMDAO = claimNodeMDAO.div(1e18).dp(6,1).toString(10)

    const hasParent = calls.parentAddr !== ZERO_ADDRESS


    const child = calls.children.map(item => {
        return {
            child: item.user,
            stakedUSDT: BN(item.stakedUSDT).div(1e18).toString(10),
        }
    })

    // console.log(child, 'child')
    const nodeProportion = claimNodeMDAO === '0' ? [0,0,0] : _nodeList.map(v => v.claim * 100 / claimNodeMDAO)
    // 0xA985D6401912b38d7Ee95dF5Db2ceB63A4F146EC
    return {
        totalPostionValue,
        claimValue,
        claimMDAO,
        claimUSDT,
        totalStaked,
        postion,
        totalShareValue,
        hasParent,
        nodeSlip: id => _nodeList[id],
        claimNodeMDAO,
        rewardedShareValue,
        parentAddr: calls.parentAddr,
        isSigned: false,
        child,
        nodeProportion
        // isSigned: calls.isSigned

    }
}

export function useTotalStaked() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const copy = useCopy()
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => init(account), setData)
    },[account, blockNubmer])

    const copyLink = () => {
        // copy(data.link)
        copy(
            window.location.origin + '/#/?r=' + account
        )
    }

    return {
        ...data,
        copyLink
    }
}

export function useStake() {
    const [hashParent, setHashParent] = useState(false)
    const stakeType = useInput('stakeUsdt')
    const parentAddress = useInput('')
    const stakeAmount = useInput('',{type:'number'})

    const {account, getBlockNumber} = useWeb3()

    useEffect(() => {
        const initHas = async () => {
            const stake = Stake()
            const hasParent = (await stake.methods.upAddress(account).call()) !== ZERO_ADDRESS
            setHashParent(hasParent)
        }
        initHas()
    },[getBlockNumber()])

    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Stake')

    const {
        button: buttonMSC,
        loading: loadingMSC,
        init: initMSC,
        txError: txErrorMSC,
        fail: failMSC,
        successful: successfulMSC
    } = useSendButton('Mix MSC')

    const coins = useMemo(() => {
        const coins = TokenAddr()
        // return stakeType.value === 'stakeUsdt' ? getUSDTAddress() : MdaoAddress()
        return coins.USDT
    }, [stakeType.value])

    const sender = useMemo(() => Stake()._address,[])

    // @params stakeType stakeUsdt & stakeMdao
    const stakeFun = async (isMSC = false) => {
        const stake = Stake()
        loading("Pending")
        const [,decimal] = TokenAddr().USDT
        const stakeAmountWei = BN(stakeAmount.value).mul(10**decimal).dp(0, 1).toString(10)
        const stakeParent = parentAddress.value === '' ? await stake.methods.upAddress(account).call() : parentAddress.value

        SendLocalOn(
            stake.methods.stakeUsdt(stakeAmountWei, stakeParent),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Stake Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Stake successful')
                }
            }
        )
    }

    const stakeMSCFun = async () => {
        const stake = Stake()
        loadingMSC("Pending")
        const [,decimal] = TokenAddr().USDT
        const stakeAmountWei = BN(stakeAmount.value).mul(10**decimal).dp(0, 1).toString(10)
        const stakeParent = parentAddress.value === '' ? await stake.methods.upAddress(account).call() : parentAddress.value

        SendLocalOn(
            stake.methods.stakeUsdtAndMSC(stakeAmountWei, stakeParent),
            {
                seed: 5,
                signDone: initMSC,
                cancel() {
                    failMSC('Mix MSC Cancel')
                },
                fail(err) {
                    txErrorMSC(err.message)
                },
                confirm () {
                    successfulMSC('Mix MSC successful')
                }
            }
        )
    }

    const disabledMin = stakeAmount.value * 1 < 100;
    const disabledMSCMix = stakeAmount.value * 1 / 0.9 < 100;
    // const disabledBurnedMET = stakeAmount.value * 1 / 0.9 < 500;
    return {
        hashParent,
        stakeType,
        parentAddress,
        stakeAmount,
        disabledMin,
        disabledMSCMix,
        approve: {
            coins: [[...coins , stakeAmount.value, true]],
            sender,
            then: stakeFun,
            loading: button.loading,
            children: button.children,
            disabled:disabledMin
        },
        approveMSC: {
            coins: [[...coins , stakeAmount.value, true]],
            sender,
            then: stakeMSCFun,
            loading: buttonMSC.loading,
            children: buttonMSC.children,
            disabled: disabledMSCMix
        }
    }
}

export function useClaim() {
    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Claim')


    const claimFun = () => {
        // console.log('claimFun')
        // 签名
        loading("Pending")
        const m3 = Stake()
        SendLocalOn(
            m3.methods.claim(account),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Claim Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Claim successful')
                }
            }
        )
    }

    return {
        button,
        claimFun
    }
}

export function useWithdraw(id) {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Unstake')


    const unstakeLpFun = async () => {
        // console.log('claimFun')
        // 签名
        loading("Pending")
        const m3 = Stake()
        SendLocalOn(
            m3.methods.unStakeLp(id),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Unstake Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Unstake successful')
                }
            }
        )
    }

    return {
        button,
        unstakeLpFun
    }
}



export function useNodeWithdraw() {
    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Claim')


    const claimNodeFun = async () => {
        
        // 签名
        loading("Pending")
        const m3 = Stake()
        SendLocalOn(
            m3.methods.claimNode(account),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Claim Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Claim successful')
                }
            }
        )
    }

    return {
        button,
        claimNodeFun
    }
}


export function useIsSigned() {
    const {getBlockNumber, account} = useWeb3()
    const [data, setData] = useState(null)

    useEffect(() => {
        if (data === true) return
        initAsyncData(async () => {
            const protocol = Protocol()
            const isSigned = await protocol.methods.isSignOf(account).call()
            // console.log('isSigned', isSigned, protocol._address)
            return isSigned
        }, setData)
    },[getBlockNumber(), account])

    return data
}

export function useSign() {

    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Agree And Sign')


    const signFun = async () => {
        const protocol = Protocol()
        const web3 = initWeb3()
        // 签名
        loading("Sign...")
        const hashedMessage = "0x4ec140aaa24fdab289942c678f29466035e56e25a3beb1379ba5cc7297601e4c"
        let signature;
        try {
            signature = await web3.currentProvider.request({
                method: "personal_sign",
                params: [hashedMessage, account],
            });
        } catch (error) {
            init()
            fail('Sign fail')
        }

        if ( signature ) {
            const r = signature.slice(0, 66);
            const s = "0x" + signature.slice(66, 130);
            const v = parseInt(signature.slice(130, 132), 16);
            loading("Register...")
            SendLocalOn(
                protocol.methods.signTerm(hashedMessage, v, r, s),
                {
                    signDone: init,
                    cont: 'Agreement MDAO Rules',
                    cancel() {
                        fail('Agreement Cancel')
                    },
                    fail(err) {
                        txError(err.message)
                    },
                    confirm () {
                        successful('Agreement successful')
                    },
                    confirmDone: init
                }
            )
        }
    }

    return {
        button,
        signFun
    }
}