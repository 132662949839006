// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    TokenAddr,
    Gnft,
    Nft360,
    PurchaseNFT
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"

// import useCopy from '../useCopy'


const INIT = {
    claim: 0, // mdao 可提取奖励余额
    totalStock: 0, // totalStock
    balanceOf: 0, // user's balance Of gnft
    stockOf: 0, // user's stock Of gnft
    claimed: 0
}

// function totalStock() external view returns (uint);
// function stockOf(address) external view returns (uint);
// function balanceOf(address) external view returns (uint);
// function userEearn(address) external view returns (uint, uint);
// function updateStockOf(address) external returns(uint);
// function purchase(uint256) external;
async function init(account) {
    const gnft = Gnft()

    // console.log("sdfsdf")
    // console.log(
    //     await  gnft.methods.stockOf(account).call()
    // )
    // console.log("userEearn userEearn")

    const calls = await multiCalls({
        totalStock: gnft.methods.totalStock(),
        claimed: gnft.methods.userEearn(account),
        balanceOf: gnft.methods.balanceOf(account),
        stockOf: gnft.methods.stockOf(account),
        claim: gnft.methods.updateStockOf(account)
    })
    // console.log(
    //     calls
    // )
    // console.log("calls userEearn userEearn")

    const claimed = BN(calls.claimed[1]).mul(0.95).div(1e18).dp(6,1).toString(10)

    const claim = BN(calls.claim).div(1e18).dp(6,1).toString(10)
    const totalStock = BN(calls.totalStock).toString(10)
    const balanceOf = BN(calls.balanceOf).toString(10)
    const stockOf = BN(calls.stockOf).toString(10)
    return {
        claim,
        totalStock,
        balanceOf,
        stockOf,
        claimed
    }
}

export function useGNFT() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => init(account), setData)
    },[account, blockNubmer])

    return {
        ...data
    }
}

// const INIT_NFT = {

// }
const NFT_PRICE = {
    GNFT: 600,
    "365NFT": 6000
}
export function usePurchase(priceMToU) {
    const pAmount = useInput('', {type:'number', placeholder: 'NFT Amount'})
    const mAmount = useInput('', {type:'number'})
    const pTpye = useInput(1, {type:'number'})
    const nftType = useInput('GNFT')
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Purchase')

    const {sender, coins} = useMemo(() => {
        // const sender = nftType.value === "GNFT" ? Gnft()._address : Nft360()._address
        const sender = PurchaseNFT()._address
        const tokens = TokenAddr()
        const coins = [
            [...tokens.USDT, 1000000, true],
            [...tokens.MDAO, 1000000, true]
        ]
        return {
            sender,
            coins
        }
    }, [])

    const needAmount = {
        1: BN(pAmount.value || 0).mul(NFT_PRICE[nftType.value]).dp(0,1).toString(10),
        2: BN(pAmount.value || 0).div(priceMToU).mul(NFT_PRICE[nftType.value] * 1.03).dp(0,1).toString(10)
    }

    const purchaseFun = () => {
        // 签名
        loading("Pending")
        const purhase = PurchaseNFT()
        let nftAddree;
        let params = [0, 0];
        if ( nftType.value === "GNFT" ) {
            const purchaseWei = BN(needAmount[pTpye.value]).mul(1e18).dp(0,1).toString(10)
            nftAddree = Gnft()._address
            params = [nftAddree, purchaseWei, 0]
            if ( pTpye.value*1 === 2 ) {
                params = [nftAddree, 0, purchaseWei]
            }
        }
        else {
            nftAddree = Nft360()._address
            const purchaseWei = BN(needAmount[1]).mul(1e18).dp(0,1).toString(10)
            params = [nftAddree, purchaseWei, 0]
        }
        // const mdaoWei = BN(mAmount.value || 0).mul(1e18).dp(0,1).toString(10)
        SendLocalOn(
            // stake.methods.purchase(purchaseWei, mdaoWei),
            purhase.methods.purchase(...params),
            {
                seed: 3,
                signDone: init,
                cancel() {
                    fail('Purchase Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Purchase successful')
                }
            }
        )
    }

    

    return {
        nftType,
        needAmount,
        pTpye,
        pAmount,
        mAmount,
        approve: {
            coins,
            sender,
            then: purchaseFun,
            loading: button.loading,
            children: button.children,
            // disabled:disabledMin
        }
    }
}

export function useClaim() {

    const {account} = useWeb3()
        
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Claim')
    
    const onClick = () => {
        // 签名
        loading("Pending")
        const stake = Gnft()
        SendLocalOn(
            stake.methods.updateStockOf(account),
            {
                seed: 3,
                signDone: init,
                cancel() {
                    fail('Purchase Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Purchase successful')
                }
            }
        )
    }

    return {
        ...button,
        onClick
    }
}