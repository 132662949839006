
import { memo } from 'react';

import { useHistory } from "react-router-dom";

import styled from 'styled-components';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip
} from '../../components'

import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'


// dialog 
import useDialog from '../../hook/useDialog'

// import useCopy from '../../hook/useCopy'

import {
    useGNFT,
    useClaim as useClaimGNFT,
} from '../../hook/pages/useGNFT'

import {useTotalStaked, useClaim, useWithdraw, useNodeWithdraw} from '../../hook/pages/useStake'
import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

import PurchaseGNFT from './PurchaseGNFT'
import Nft360 from './Nft360'

import {SpringPoolHome} from '../SpringPool'

import {useSmiCountdown} from '../../hook/useCountdown'

const { shortAddress } = utils


function level(staked) {
    if ( staked * 1 >= 6000 ) {
        return 3
    }

    else if ( staked * 1 >= 3000 ) {
        return 2
    }
    
    else if ( staked * 1 >= 1500 ) {
        return 1
    }
     
    else return 0
}


function UnStake({end, id}) {
    const res = useSmiCountdown(end)
    const {button, unstakeLpFun} = useWithdraw(id)
    // console.log(res,res.end)
    return res.end ? <Button size='0' {...button} onClick={unstakeLpFun}/> : <Button size='0' disabled={true}>{res.str}</Button>
}



const ClaimMemo = memo(
    () => {
        const {button, claimFun} = useClaim()
        return <Button size='0' {...button} onClick={claimFun}/>
    }
)

const ClaimNFTMemo = memo(
    () => <Button status='3' size='0' {...useClaimGNFT()}/>
)

const UnstakeMemo = memo(UnStake)

const NodeUnstakeMemo = memo(
    () => {
        const {button, claimNodeFun} = useNodeWithdraw()
        return <Button status='3' size='0' {...button} onClick={claimNodeFun} />
    }
)


const LEVEL = n => {
    n *= 1
    return n === 0 ? '0' : n === 1 ? '1' : n === 2 ? '2' : '3'
}
function GNft() {
    const {
        claim,
        totalStock,
        balanceOf,
        stockOf,
        claimed
    } = useGNFT()
    const {open} = useDialog(<PurchaseGNFT />)
    const le = LEVEL(stockOf)
    return (
        <Card decorate='0'>
            <FlexBlock flex justify='start'>
                <ILogo>
                    <Level src={`./gnft/v${le}.png`} />
                </ILogo>
                <div
                    style={{
                        marginLeft: '10px',
                        flex: '2'
                    }}
                >
                    <TextSM color='2'>My Glory NFT <Hot>v{le}</Hot></TextSM>
                    <br />
                    <TextMD >{wFormatInt(stockOf)}</TextMD>
                    <br />
                    <TextSM color='2'> My Total <TextLinear size='0'>{balanceOf}</TextLinear> NFT </TextSM>
                </div>
                <div style={{flex: '1', textAlign:'right'}}>
                    <Button
                        // onClick={open}
                        size='0'
                        disabled
                    >Buy NFT</Button>
                </div>
            </FlexBlock>
            
            {/* <br />
            <TextSM color='2'>Holding GNFT can earn MDAO slippage rewards.</TextSM> */}
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>Available MDAO</TextSM>
                    <br />
                    <TextMD>{claim}</TextMD>
                    {/* <br />
                    <TextSM color='2'>Claimed <TextLinear size='0'>{claimed}</TextLinear> MDAO</TextSM> */}
                </div>
                <ClaimNFTMemo />
            </FlexBlock>
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>Claimed MDAO</TextSM>
                <TextSM >{claimed}</TextSM>
            </FlexBlock>
            {/* <FlexBlock flex>
                <TextSM color='2'>Total Stock</TextSM>
                <TextSM >{wFormatInt(totalStock)}</TextSM>
            </FlexBlock> */}
            {/* <WhiteSpace />
            <Hr />
            <WhiteSpace />
            
            <FlexBlock flex>
                <TextSM color='2'>My GNFT</TextSM>
                <TextSM >{wFormatInt(balanceOf)}</TextSM>
            </FlexBlock>
            <FlexBlock flex>
                <TextSM color='2'>My Stock</TextSM>
                <TextSM >{wFormatInt(stockOf)}</TextSM>
            </FlexBlock> */}
            {/* <WhiteSpace /> */}
        </Card>
    )
}

function Home() {
    const bal = useBalance()
    const staked = useTotalStaked()
    const mdaoOrcal = bal.oracle('MDAO')
    
    
    // const nodeList = 

    // console.log(nodeList,'nodeList')

    const {integer, decimalTail, decimal0} = splitNumber(mdaoOrcal.price)
    
    const {open} = useDialog()
    // staked.parentAddr
    const openDialog = () => {
        open(<StakeDialog hasParent={staked.hasParent}/>)
    }
    const history = useHistory();

    function goSwap() {
        history.push("/swap");
    }

    // const copyLink = () => {
    //     copy()
    // }


    return (
        <Container>
            {/* <Card decorate='1' style={{textAlign: 'left'}}>
                <TextSM color='2'>MDAO / USDT</TextSM>
                <br />
                <TextGL>1230.<sup>6</sup>1231</TextGL>
            </Card> */}
            <FlexBlock style={{padding:'1rem 1.2rem'}} flex>
                <div decorate='1' style={{textAlign: 'left'}}>
                    <Tooltip
                        title={
                            <>
                                <TextSM>{mdaoOrcal.pricePath[0]} {wFormatInt(mdaoOrcal.lpRs[0])}</TextSM>
                                <WhiteSpace />
                                <TextSM>{mdaoOrcal.pricePath[1]} {wFormatInt(mdaoOrcal.lpRs[1])}</TextSM>
                            </>
                            
                        }
                        placement="right"
                        arrow
                    >
                        <TextSM color='2'>MDAO / USDT <Icon size='12' type='icon-prompt' /></TextSM>
                    </Tooltip> 
                    <br />
                    <TextGL>{wFormatInt(integer)}.{decimal0 !== 0 ? <sup>{decimal0}</sup> : null }{(decimalTail.slice(0,4)*1)}</TextGL>
                </div>
                <Button status='0' onClick={goSwap}>Buy</Button>
            </FlexBlock>
            <WhiteSpace />
            <GNft />
            <WhiteSpace />
            <SpringPoolHome />
            <WhiteSpace />
            <Nft360 />
            <WhiteSpace />
            <Card decorate='1' style={{textAlign: 'left'}}>
                <FlexBlock flex>
                    <div>
                        <Tooltip
                            title={
                                <div style={{width: '100px'}}>
                                    <FlexBlock flex>
                                        <TextSM style={{flex: 1}}>Staked</TextSM>
                                        <TextSM style={{flex: 1, textAlign:'right'}}>Level</TextSM>
                                    </FlexBlock>
                                    <WhiteSpace />
                                    <FlexBlock flex>
                                        <TextSM style={{flex: 1}}>{'>'}= 1500</TextSM>
                                        <TextSM style={{flex: 1, textAlign:'right'}}>v1</TextSM>
                                    </FlexBlock>
                                    <FlexBlock flex>
                                        <TextSM style={{flex: 1}}>{'>'}= 3000</TextSM>
                                        <TextSM style={{flex: 1, textAlign:'right'}}>v2</TextSM>
                                    </FlexBlock>
                                    <FlexBlock flex>
                                        <TextSM style={{flex: 1}}>{'>'}= 6000</TextSM>
                                        <TextSM style={{flex: 1, textAlign:'right'}}>v3</TextSM>
                                    </FlexBlock>
                                </div>
                                
                            }
                            placement="right"
                            arrow
                        >
                            <TextSM color='2'>Slippage Bonus <Hot>v{level(staked.totalStaked)}</Hot> <Icon size='12' type='icon-help' /></TextSM>
                        </Tooltip> 
                        <br />
                        <TextMD>{staked.claimNodeMDAO} <TextSM color='2'> / MDAO</TextSM></TextMD>
                    </div>
                    <NodeUnstakeMemo />
                </FlexBlock>
                {/* <WhiteSpace />
                <FlexBlock flex>
                    <CircleArr
                        style={{flex: 2}}
                        // per={[ 1, 1, 2 ]}
                        per={staked.nodeProportion}
                        color={[
                            ['#34a8c2', '#6d4ebe'],
                            ['#61cb9a', '#cbc861'],
                            ['#edc457', '#f7a67d']
                        ]}
                    />
                    <div
                        style={{
                            flex: 3,
                            textAlign: 'left',
                        }}

                    >
                        <Hot style={{background: '#567dc0'}}>-</Hot> <TextSM color='2'>V1 <TextSM>{staked.nodeSlip(0).claim}</TextSM> MDAO</TextSM>
                        <WhiteSpace />
                        <Hot style={{background: '#cbc861'}}>-</Hot> <TextSM color='2'>V2 <TextSM>{staked.nodeSlip(1).claim}</TextSM> MDAO</TextSM>
                        <WhiteSpace />
                        <Hot style={{background: '#edc457'}}>-</Hot> <TextSM color='2'>V3 <TextSM>{staked.nodeSlip(2).claim}</TextSM> MDAO</TextSM>
                    </div>
                    
                </FlexBlock> */}
            </Card>
            <WhiteSpace />
            <Card decorate='1' style={{textAlign: 'left'}}>
                <TextMD >GLORY POOL</TextMD>
                <br />
                <TextSM color='2'> Pledge USDT minnig MDAO, APR 24%.</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div>
                        <TextSM color='2'>Value Available</TextSM>
                        <br />
                        <TextMD>{staked.claimValue}</TextMD>
                        <br />
                        <TextSM color='2'>Estimate <TextLinear size='0'>{staked.claimMDAO}</TextLinear> MDAO</TextSM>
                    </div>
                    <ClaimMemo />
                </FlexBlock>
                <WhiteSpace />
                {/* <FlexBlock flex>
                    <TextSM color='2'>Estimate </TextSM>
                    <TextSM color='2'><TextLinear size='0'>314,421,533.041</TextLinear> MDAO</TextSM>
                </FlexBlock> */}
                <FlexBlock flex>
                    <Tooltip
                        title={
                            <div style={{width: '240px'}}>
                                {/* child */}
                                <FlexBlock flex>
                                    <TextSM style={{flex: 1}}>User</TextSM>
                                    <TextSM style={{flex: 1, textAlign:'right'}}>Staked</TextSM>
                                </FlexBlock>
                                <WhiteSpace />
                                {
                                    staked.child.map(v => {
                                        return (
                                            <FlexBlock flex key={v.child}>
                                                <TextSM style={{flex: 1}}>{shortAddress(v.child, 6)}</TextSM>
                                                <TextSM style={{flex: 1, textAlign:'right'}}>{wFormatInt(v.stakedUSDT)}</TextSM>
                                            </FlexBlock>
                                        )
                                    })
                                }
                            </div>
                            
                        }
                        placement="top"
                        arrow
                    >
                        <TextSM color='2'>Cash Available <Icon size='12' type='icon-help' /></TextSM>
                    </Tooltip> 
                    <TextSM color='2'><TextSM>{staked.claimUSDT}</TextSM> USDT</TextSM>
                </FlexBlock>
                {/* <FlexBlock flex>
                    <TextSM color='2'>Slippage Bonus </TextSM>
                    <TextSM color='2'><TextSM>{staked.claimNodeMDAO}</TextSM> MDAO</TextSM>
                </FlexBlock> */}
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div onClick={staked.copyLink} >
                    {/* <Icon size='12' type='icon-fenxiang' /></TextSM> */}
                        <TextSM color='2'>My Staked <TextLinear size='12'>Copy Link</TextLinear></TextSM>
                        <br />
                        <TextMD>{wFormatInt(staked.totalStaked)} <TextSM color='2'> / USDT</TextSM></TextMD>
                    </div>
                    <Button size='0' onClick={openDialog}>Stake</Button>
                </FlexBlock>
                <WhiteSpace />
                <FlexBlock flex style={{marginBottom:'6px'}}>
                    <TextSM color='2'>Released Value</TextSM>
                    <TextSM>{wFormatInt(staked.rewardedShareValue)}</TextSM>
                </FlexBlock>
                <Progress w='100' value={staked.totalShareValue*1 === 0 ? 0 : (staked.rewardedShareValue * 100 / staked.totalShareValue)}/>
                <FlexBlock flex style={{marginTop:'6px'}}>
                    <TextSM color='2'>Total Share Value <Hot>x{staked.totalStaked * 1 === 0 ? 0 : Math.floor(staked.totalShareValue / staked.totalStaked * 100) / 100}</Hot></TextSM>
                    <TextSM>{wFormatInt(staked.totalShareValue)}</TextSM>
                </FlexBlock>
            </Card>
            <WhiteSpace />
            <Card decorate='1' style={{textAlign: 'left'}}>
                <FlexBlock flex>
                    <TextM color='2'>Rewarded Value</TextM>
                    <TextMD>{wFormatInt(staked.totalPostionValue)}</TextMD>
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                {
                    staked.postion.map(v => (
                        <div key={v.id}>
                            <WhiteSpace />
                            <FlexBlock flex>
                                <FlexBlock flex key={v.id} justify='start'>
                                    <Hot style={{marginRight:'6px'}}>{v.id+1}</Hot>
                                    <div>
                                        <TextSM color='2'>Staked USDT <TextSM>{wFormatInt(v.totalSated)}</TextSM></TextSM>
                                        <br />
                                        <Tooltip
                                            title={
                                                <>
                                                    <TextSM>LP Value {wFormatInt(v.lpDetail.value)}</TextSM>
                                                    <WhiteSpace />
                                                    <TextSM>MDAO {wFormatInt(v.lpDetail.mdao)}</TextSM>
                                                    <WhiteSpace />
                                                    <TextSM>USDT {wFormatInt(v.lpDetail.usdt)}</TextSM>
                                                </>
                                                
                                            }
                                            placement="top"
                                            arrow
                                        >
                                            <TextSM color='2'>Staked LP <TextSM>{wFormatInt(v.lpStaked)}</TextSM> <Icon style={{ color: '#fff' }} size='12' type='icon-prompt' /></TextSM>
                                        </Tooltip>
                                        <br />
                                        <TextSM color='2'>Rewarded Value <TextSM>{v.rewardedValue}</TextSM></TextSM>
                                    </div>
                                </FlexBlock>
                                <UnstakeMemo end={v.unlockSeconds} id={v.id} />
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                        
                    ))
                }
            </Card>
            <WhiteSpace size='1'/>
        </Container>
    );
}

export default Home;



const Level = styled.img`
    /* position: relative; */
    display: block;
    width: 50px;
`

const ILogo = styled.div`
    position: relative;
    float: left;
    /* margin: 18px 0 0 5px; */
    overflow: hidden;
    transition-duration: 5s;/**动画时间**/
    &::before {
        content: "";
        position: absolute;
        width: 1000px;
        height: 20px; /**光标的宽度，可根据实际调整**/
        background-image: linear-gradient(to bottom,transparent,rgba(255,255,255,.5),transparent);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-animation: searchLights 2s ease-in 1s infinite;
        -o-animation: searchLights 2s ease-in 1s infinite;
        animation: searchLights 2s ease-in 1s infinite;
    }
    @keyframes searchLights {
        0% {
            left: -200px;
            top: -300px;
        }
        100% {
            left: -160px;
            top: 800px;
        }
    }
`