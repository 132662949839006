
import {BN} from '../web3'

export function getAmountOut(amountIn, reserveIn, reserveOut, slippage) {
    amountIn = BN(amountIn)
    reserveIn = BN(reserveIn)
    reserveOut = BN(reserveOut)
    // console.log(slippage, (1 - slippage)*1000,reserveIn, reserveOut,'slippage')
    const amountInWithFee = amountIn.multipliedBy((1 - slippage)*1000);
    const numerator = amountInWithFee.multipliedBy(reserveOut);
    const denominator = reserveIn.multipliedBy(1000).plus(amountInWithFee);
    return numerator.div(denominator).decimalPlaces(18,1).toString(10);
}

// slippage = 0.0025
export function getAmountIn(amountOut, reserveIn, reserveOut, slippage) {
    amountOut = BN(amountOut)
    reserveIn = BN(reserveIn)
    reserveOut = BN(reserveOut)
    const numerator = reserveIn.multipliedBy(amountOut).multipliedBy(1000);
    const denominator = reserveOut.minus(amountOut).multipliedBy((1 - slippage)*1000);
    return numerator.div(denominator).decimalPlaces(18,1).toString(10);
}

