import React, { useMemo, useState } from 'react'
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    TextGL,
    TextMD,
    TextSM,
    TextXL,
    WhiteSpace,
    FlexBlock,
    FlexBlockAuto,
    Block,
    Button,
    Hr,
    Container,
    Tooltip,
    Icon,
    TextM,
    TabStyled,
    HrCol,
    Hot,
    APR,
    TextLinear,
    Fragment,
    Polyline,
    Coin,
    TextP,
    Text,
    HrDotted,
    SquareDot,
    // CardBox,
    Input
} from "../../components";
import { wFormatInt, BN, splitNumber } from '../../web3'

import { useSlippage } from '../../hook'
// import { wFormatInt, splitNumber } from 'web3'

import {useGetPair, useSwap} from '../../hook/pages/useSwap'
// import useGo from '../../hook/pages/swap/useGo'
// import { useSwapV0 } from '../../hook/pages/swap/useSwapV0';
import Approve from '../../contractComponents/Approve';


// import useMirrorBalance from '../../hook/pages/useMirrorBalance'

const useStyles = makeStyles(() => ({
    inputWrap: {
        backgroundColor: '#fff !important',
        color: '#000'
    },
    ml3: {
        marginLeft: '3px'
    },
    ml5: {
        marginLeft: '5px'
    },
    mt5: {
        marginTop: '5px'
    }
}));

const ItemWrap = ({ coin = {}, ...other }) => {
    const classes = useStyles();
    //  ['0x2bC86CF8b0D723934d5a48789e2AEE1212039673', 18, 'MIS']
    // console.log('coin[2]--', coin)
    return (
        <FlexBlockAuto flex {...other}>
            <Coin size='32' type={coin[2]} />
            <FlexBlockAuto className={classes.ml3} flex justify="start">
                <TextM>{coin[2]}</TextM>
                <Icon className={classes.ml5} color='#fff' type='icon-arrow-down' />
            </FlexBlockAuto>
        </FlexBlockAuto>
    )
}

function Slippage() {
    const { open } = useSlippage()
    return (
        <Icon color='#9CA3AF' onClick={open} type='icon-setting' />
    )
}

function usePriceRer() {
    const [re, setRe] = useState(false)
    const reverse = () => setRe(v => !v)
    return [re, reverse]
}
function Price({ fromName, toName, fromValue, toValue }) {
    const [isPriceRev, priceRev] = usePriceRer()
    if (isPriceRev) {
        [toName, fromName] = [fromName, toName];
        [toValue, fromValue] = [fromValue, toValue];
    }
    const sp = splitNumber(fromValue / toValue, 4)
    return (
        <FlexBlockAuto>
            <TextSM
                onClick={priceRev}
            >
                {toValue*1 <= 0 ? 0 : `${sp.integer}.${sp.decimal0 !== 0 ? "[" + sp.decimal0 +"]" : ''}${sp.decimalTail.slice(0,4)}`} {fromName} / {toName} <Icon size='12' type='icon-shuaxin' />
            </TextSM>
        </FlexBlockAuto>
    )
}
export default function SwapTokenView() {
    const {
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        amountOutMin,
        switchRes: handleSwitch,
        // resetToken,
        slippage,
        tokenInBalance,
        tokenOutBalance,
        approve,
        status
    } = useSwap()

    const setMaxFrom = () => {
        amountIn.onChange(tokenInBalance)
    }
    return (
        <Container align='left'>
            <WhiteSpace size='1'/>
            <CardBoxStake flex decorate='1'>
                <Block>
                    <FlexBlock flex>
                        <div>
                            <TextMD>MDAO Swap</TextMD>
                            <br />
                            <TextSM style={{ marginTop: '6px' }} color={1}>Easy way to trade your tokens</TextSM>
                        </div>
                        <Slippage />
                    </FlexBlock>
                    <WhiteSpace size='2' />
                    <FlexBlock flex>
                        <TextM color='2' >From</TextM>
                        <TextSM color='2' onClick={setMaxFrom} >Balance: <TextLinear size='0'>{wFormatInt(tokenInBalance)}</TextLinear>
                            <TextSM
                                style={{
                                    backgroundColor: "#FED215",
                                    color: "#000",
                                    padding: "0px 2px",
                                    marginLeft: '4px',
                                    borderRadius: '2px'
                                }} b={800}>ALL</TextSM>
                        </TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <Input
                        // square
                        before={<Coin size='32' type={tokenIn[2]} />}
                        input={amountIn}
                        after={<TextSM>{tokenIn[2]}</TextSM>}
                    />
                    <WhiteSpace size={1} />
                    <Block center my="10px" >
                        <Icon
                            color='#fff'
                            onClick={handleSwitch}
                            type='icon-swap-svgrepo-com'
                        />
                    </Block>
                    {/* <WhiteSpace size={1} /> */}
                    <FlexBlock flex>
                        <TextM color='2' >To</TextM>
                        <TextSM color='2'>Balance: <TextLinear size='0'>{wFormatInt(tokenOutBalance)}</TextLinear></TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <Input
                        // square
                        before={<Coin size='32' type={tokenOut[2]} />}
                        input={{
                            value: amountOut,
                            onlyread: true
                        }}
                        after={<TextSM>{tokenOut[2]}</TextSM>}
                    />
                    <WhiteSpace size={1} />
                    <FlexBlock flex>
                        <Tooltip
                            title={
                                <TextSM>Your transaction will revert if there is a large,unfavorable price movement before it is confirmed.</TextSM>
                            }
                            placement="top"
                            arrow
                        >
                            <TextSM color='2'>Minimum <Icon style={{ color: '#fff' }} size='12' type='icon-prompt' /></TextSM>
                        </Tooltip>
                        <TextSM>{amountOutMin} {tokenOut[2]}</TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextSM color='2'>Cost Price </TextSM>
                        <Price
                            fromName={tokenIn[2]}
                            toName={tokenOut[2]}
                            fromValue={amountIn.value}
                            toValue={amountOutMin}
                        />
                    </FlexBlock>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextSM color='2'>Slippage tolerance</TextSM>
                        <TextSM>{slippage} %</TextSM>
                    </FlexBlock>
                    {/* <WhiteSpace />
                    <FlexBlock flex>
                        <Tooltip
                            title={
                                <TextSM>The difference between the market price and estimated price due to trade size.</TextSM>
                            }
                            placement="top"
                            arrow
                        >
                            <TextSM color='2'>Price Impact <Icon style={{ color: '#fff' }} size='12' type='icon-prompt' /></TextSM>
                        </Tooltip>
                        <TextSM>{P1} | {wFormatInt(priceImpact)} %</TextSM>
                    </FlexBlock> */}
                    <WhiteSpace size={2} />
                    <Approve
                        w='100'
                        size='1'
                        status='1'
                        {...approve}
                        loading={status.loading}
                    />
                </Block>
            </CardBoxStake>
            {/* <WhiteSpace size={2} /> */}
            {/* <CardBox>
                <FlexBlock flex>
                    <TextSM>
                        <Tooltip
                            title={"Minimum received"}
                            placement="top"
                            arrow
                        >
                            Minimum received <Icon style={{ color: '#fff' }} size='14' type='icon-prompt' />
                        </Tooltip>
                    </TextSM>
                    <TextSM>{!!otherInfo.minValue && otherInfo.minValue.toString(10)} USDT  </TextSM>
                </FlexBlock>
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM>
                        <Tooltip
                            title={"Price Impact"}
                            placement="top"
                            arrow
                        >
                            Price Impact <Icon style={{ color: '#fff' }} size='14' type='icon-prompt' />
                        </Tooltip>
                    </TextSM>
                    <TextSM color='2'>{wFormatInt(priceImpact*100)} %</TextSM>
                    {!!otherInfo.priceImpact && <TextSM> {`<${otherInfo.priceImpact.toString(10).toFixed(4)} %`}  </TextSM>}
                </FlexBlock>
                <FlexBlockList flex>
                    <TextSM>
                        <Tooltip
                            title={"Liquidity Provider Fee"}
                            placement="top"
                            arrow
                        >
                            Liquidity Provider Fee <Icon style={{ color: '#fff' }} size='14' type='icon-prompt' />
                        </Tooltip>
                    </TextSM>
                    <TextSM>61 USDT   </TextSM>
                </FlexBlockList>
                <FlexBlockList flex>
                    <TextSM>Route </TextSM>
                    <TextSM> </TextSM>
                </FlexBlockList>
            </CardBox> */}
        </Container>
    )
}
const CoinCellWap = styled(Block)`
    // background: #F9FAFB;
    // border: 0.6px solid #E5E7EB;
    border-radius: 12px;
    padding:1rem 0rem;
`;

const CardBoxStake = styled(Card)`
    overflow: initial;
    padding-top: 24px;
    padding-bottom: 30px;
`;

const FlexBlockList = styled(FlexBlock)`
    padding: 1rem 0;
`;