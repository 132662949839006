import { memo } from 'react';

import { useHistory } from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip
} from '../../components'

// import StakeDialog from '../StakeDialog'

// import useBalance from '../../hook/pages/useBalance'


// dialog 
import useDialog from '../../hook/useDialog'
import { useInput } from '../../hook';

import {
    useSetBlack,
    useRemoveBlack
} from '../../hook/pages/useMUSDT'

import useCopy from '../../hook/useCopy'

import {useTotalStaked, useClaim, useWithdraw, useNodeWithdraw} from '../../hook/pages/useStake'
import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

import {useSmiCountdown} from '../../hook/useCountdown'




function UAdmin() {
    const addr = useInput('')
    const addBlack = useSetBlack(addr.value)
    const removeBlack = useRemoveBlack(addr.value)
    
    return (
        <Container>
            <Card>
                <TextMD >UAdmin</TextMD>
                <WhiteSpace />
                <TextM >Set Black List</TextM>
                <TextSM>Address</TextSM>
                <Input
                    input={addr}
                />
                <WhiteSpace />
                <FlexBlock flex>
                    <Button w='48' {...addBlack} />
                    <Button w='48' status='3' {...removeBlack}/>
                </FlexBlock>
            </Card>
        </Container>
    )
}

export default UAdmin