// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    Compensation,
    CompensationV1,
    TokenAddr
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"


const INIT = {
    claim: 0, // mdao 可提取奖励余额
    totalStaked: 0, // met 总质押量
    activatedMet: 0, // met 已激活量
    metAmount: 0, // met 使用数量
    rewardedValue: 0, // mdao 已领价值
    v1: {
        claim: 0, // mdao 可提取奖励余额
        totalStaked: 0, // met 总质押量
        activatedMet: 0, // met 已激活量
        metAmount: 0, // met 使用数量
        rewardedValue: 0, // mdao 已领价值
    }
}


async function init(account) {
    const compensation = Compensation()
    const compensationV1 = CompensationV1()

    // console.log(
    //     await compensation.methods.totalRegisterMet().call(),
    //     "123123"
    // )
    // console.log(
    //     await compensationV1.methods.userDeposit(account).call(),
    // )
    // console.log(
    //     await compensationV1.methods.claimFor(account).call(),
    //     account
    // )

    const calls = await multiCalls({
        claim: compensation.methods.claimFor(account),
        totalRegisterMet: compensation.methods.totalRegisterMet(),
        userDeposit: compensation.methods.userDeposit(account),
        claimV1: compensationV1.methods.claimFor(account),
        totalRegisterMetV1: compensationV1.methods.totalRegisterMet(),
        userDepositV1: compensationV1.methods.userDeposit(account),
    })

    // console.log(
    //     calls, " calls"
    // )

    const claim = BN(calls.claim).div(1e18).dp(6,1).toString()
    const totalStaked = BN(calls.totalRegisterMet).div(1e18).toString()
    const activatedMet = BN(calls.userDeposit.activatedMet).div(1e18).toString()
    const metAmount = BN(calls.userDeposit.metAmount).div(1e18).toString()
    const rewardedValue  = BN(calls.userDeposit.rewardValue).div(1e18).dp(6,1).toString()

    const claimV1 = BN(calls.claimV1).div(1e18).dp(6,1).toString()
    const totalStakedV1 = BN(calls.totalRegisterMetV1).div(1e18).toString()
    const activatedMetV1 = BN(calls.userDepositV1.activatedMet).div(1e18).toString()
    const metAmountV1 = BN(calls.userDepositV1.metAmount).div(1e18).toString()
    const rewardedValueV1  = BN(calls.userDepositV1.rewardValue).div(1e18).dp(6,1).toString()

    // console.log(

    // )
    // console.log(
    //     calls.userDepositV1
    // )
    return {
        claim,
        totalStaked,
        activatedMet,
        metAmount,
        rewardedValue,
        v1: {
            claim: claimV1,
            totalStaked: totalStakedV1,
            activatedMet: activatedMetV1,
            metAmount: metAmountV1,
            rewardedValue: rewardedValueV1,
        }
    }
}


export function useCompensation() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => init(account), setData)
    },[account, blockNubmer])
    return {
        ...data
    }
}



export function useRegisterMet() {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Activate')


    const registerFun = () => {
        // 签名
        loading("Pending")
        const com = Compensation()
        SendLocalOn(
            com.methods.register(),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Activate Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Activate successful')
                }
            }
        )
    }

    return {
        ...button,
        onClick: registerFun
    }
}


export function useClaim() {
    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Claim')


    const claimFun = () => {
        
        // 签名
        loading("Pending")
        const com = Compensation()
        SendLocalOn(
            com.methods.claimFor(account),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Claim Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Claim successful')
                }
            }
        )
    }

    return {
        ...button,
        onClick: claimFun
    }
}



// 结算历史记录
export function useSettleHistory() {
    const [status, setLoading] = useState({
        data: {},
        loading: false,
        erre: null
    })

    const success = (data) => setLoading({data, loading: false, error: null})
    const fail = (error) => setLoading({loading: false, error, data: {}})
    const pending = () => setLoading({loading: true, error: null, data: {}})

    const getHistory = async () => {
        const com = Compensation()
        pending()
        try {
            const list = await com.getPastEvents('Settle', {
                fromBlock: 460899,
                toBlock: 'latest'
            })
            // const list = await com.events.Settle({},function(error, event){ console.log(event, '123123'); })
            success({
                list: list.map(item => {
                    const {returnValues, blockNumber} = item
                    const mdaoAmount = BN(returnValues.mdaoAmount).div(1e18).dp(6,1).toString()
                    const rewardValue = BN(returnValues.rewardValue).div(1e18).dp(6,1).toString()
                    return {
                        mdaoAmount,
                        rewardValue,
                        blockNumber
                    }
                }).sort((a, b) => b.blockNumber - a.blockNumber)
            })
        } catch (error) {
            console.log(error, " error")
            fail(error.message)
        }
        
    }

    useEffect(() => {
        getHistory()
    }, [])

    return {
        ...status
    }
}


/////////////////////////////////////// Pro ///////////////////////////////////////
export function useRegisterMetPro() {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Burn ALL')


    const registerFun = () => {
        // 签名
        loading("Pending")
        const com = CompensationV1()
        SendLocalOn(
            com.methods.registerV1(false),
            {
                seed: 5,
                signDone: init,
                cont: `Burn All`,
                cancel() {
                    fail('Burn Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Burn successful')
                }
            }
        )
    }

    const [
        coins,
        sender
    ] = useMemo(() => {
        const coins = TokenAddr()
        const sender = CompensationV1()._address
        // return stakeType.value === 'stakeUsdt' ? getUSDTAddress() : MdaoAddress()
        return [coins.MET, sender]
    },[])

    return {
        approve: {
            coins: [[...coins , 10000000, true]],
            sender,
            then: registerFun,
            loading: button.loading,
            children: button.children,
            // disabled: disabledMSCMix
        }
    }
}

export function useRegisterMetMintPro() {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Mint MSC')


    const registerFun = () => {
        // 签名
        loading("Pending")
        const com = CompensationV1()
        SendLocalOn(
            com.methods.registerV1(true),
            {
                seed: 5,
                signDone: init,
                cont: `Mint MSC`,
                cancel() {
                    fail('Mint Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Mint successful')
                }
            }
        )
    }

    const [
        coins,
        sender
    ] = useMemo(() => {
        const coins = TokenAddr()
        const sender = CompensationV1()._address
        // return stakeType.value === 'stakeUsdt' ? getUSDTAddress() : MdaoAddress()
        return [coins.MET, sender]
    },[])

    return {
        approve: {
            coins: [[...coins , 10000000, true]],
            sender,
            then: registerFun,
            loading: button.loading,
            children: button.children,
            // disabled: disabledMSCMix
        }
    }
}


export function useClaimPro() {
    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Claim')


    const claimFun = () => {
        
        // 签名
        loading("Pending")
        const com = CompensationV1()
        SendLocalOn(
            com.methods.claimFor(account),
            {
                seed: 5,
                signDone: init,
                cancel() {
                    fail('Claim Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Claim successful')
                }
            }
        )
    }

    return {
        ...button,
        onClick: claimFun
    }
}