

// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    MUsdt
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS } from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"

import useCopy from '../useCopy'

// imp


export function useSetBlack(addr) {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Add Black')


    const onClick = async () => {
        // 签名
        loading("Pending")
        const mu = MUsdt()
        const {confirmation} = SendOn(
            mu.methods.setBlacklist(addr, true),
            {seed: 5}
        )
        const [err, status] = await confirmation()
        init()
        if (status === -1) {
            txError(err.message)
            return
        }
        if (err !== null) {
            fail('Add fail')
            return
        }
        successful('Add successful')
    }

    return {
        ...button,
        onClick
    }
}

export function useRemoveBlack(addr) {

    
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Remove Black')


    const onClick = async () => {
        // 签名
        loading("Pending")
        const mu = MUsdt()
        console.log(
            mu,'sdf'
        )
        const {confirmation} = SendOn(
            mu.methods.setBlacklist(addr, false),
            {seed: 5}
        )
        const [err, status] = await confirmation()
        init()
        if (status === -1) {
            txError(err.message)
            return
        }
        if (err !== null) {
            fail('Remove fail')
            return
        }
        successful('Remove successful')
    }

    return {
        ...button,
        onClick
    }
}