import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip
} from '../../components'

import { useSign } from '../../hook/pages/useSpring'

function Protocol () {
    const {
        button,
        signFun
    } = useSign()
    return (
        <Container>
            <Card>
                <TextMD>MedinaDAO Spring Pool Terms of Use</TextMD>
                <WhiteSpace />
                <TextM>Please review and confirm that you agree to the terms below before participating.</TextM>
                <WhiteSpace />
                <TextSM>Spring Pool Basic Terms and Conditions</TextSM>
                <WhiteSpace />
                <TextSM>1. The rate of return announced by Spring Pool is based on the number of coins, not the amount staked. Therefore, I clearly recognize that the increased number of tokens does not imply an increase in asset value.</TextSM>
                <WhiteSpace />
                <TextSM>2. The investment period in MedinaDAO Spring Pool cannot be determined by the individual, and the period determined by the individual through the random game is determined among 16 periods such as 15, 20, 25,..85, and 90 days.</TextSM>
                <WhiteSpace />
                <TextSM>3. The return rate of the Spring pool is not fixed return, but volatility return is provided.</TextSM>
                <WhiteSpace />
                <TextSM>4. DAO Community Code of Ethics The MDAO community is a DAO formed with the goal of mutual respect and common development. Therefore, defamation of others or malicious incitement to hinder the development of the community, especially any act of indiscriminately using the media to influence the public is not permitted.</TextSM>
                <WhiteSpace />
                <TextSM>5. There are many cases of abuse of the referral system provided for community development. In this regard, no excuses or clarifications are required, and as soon as a double or triple selfish referral profit structure is discovered, all the accounts and the referrer's account will be blacklisted.</TextSM>
                <WhiteSpace />
                <TextSM>6.Glory Pool Terms and Conditions apply For other matters not described in the operating rules of the Spring pool, the terms and conditions of the Glory Pool are equally applied to the Spring pool.</TextSM>
                <WhiteSpace />
                <TextSM>I fully agree to the above terms and conditions and participate in Spring Pool.</TextSM>
                <WhiteSpace />
                <Button w='100' status='2' {...button} onClick={signFun} />
            </Card>
        </Container>
        
    )
}

export default Protocol


