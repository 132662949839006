import IFatory from './ABI/IFatory.json'
import IRouter from './ABI/IRouter.json'
import IMPair from './ABI/IMPair.json'

import {ERC20, newContract} from "../web3";
import {getConstants} from './constants' 

export const Factory = () => newContract(getConstants().FACTORY, IFatory)
export const Router = () => newContract(getConstants().ROUTER, IRouter)
export const MPair = address => newContract(address, IMPair)

export const BasePairs = () => getConstants().BASE_PAIRS

export const SwapDefault = () => getConstants().SWAP_DEFAULT