import { memo } from 'react';
import styled from 'styled-components';
// import { useHistory } from "react-router-dom";
import { wFormatInt } from 'web3-sam/dist/BigNumber';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,
    NoData,
    Tips
} from '../../components'

// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'
import {
    useGovernConsole,
    useGovernStake,
    useClaim
} from '../../hook/pages/useGovern'
// import {
//     useCompensation,
//     useRegisterMet,
//     useClaim,
//     useSettleHistory,
//     useRegisterMetPro,
//     useRegisterMetMintPro,
//     useClaimPro
// } from '../../hook/pages/useCompensation'

import Approve from '../../contractComponents/Approve';

const CliamMemo = memo(() => <Button size='0' {...useClaim()} />);

function Govern() {
    const {
        balance,
        claimMEER,
        claimMDAO,
        // totalStaked,
        position,
        // positionStaked,
        availableWithdraw
    } = useGovernConsole()

    const bal = useBalance()
    const {
        stakeAmount,
        disabledMin,
        approve
    } = useGovernStake()
    
    return (
        <Container>
            <Card decorate='1'>
                <TextMD>Govern MDAO</TextMD>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div>
                        <TextSM color='2'>Earn Available</TextSM>
                        <br />
                        <TextMD>{claimMDAO} <TextSM color='2'>/ MDAO</TextSM></TextMD>
                        <span style={{padding:'0px 6px'}}>·</span>
                        <TextMD>{claimMEER} <TextSM color='2'>/ MEER</TextSM></TextMD>
                        <br />
                        <TextSM color='2'>Available Withdraw <TextSM>{availableWithdraw}</TextSM></TextSM>
                    </div>
                    <CliamMemo />
                </FlexBlock>
                <WhiteSpace />
                <TextSM color='2' >Balance <TextLinear size='0'>{wFormatInt(bal.balance.MDAO)}</TextLinear> MDAO</TextSM>
                <Input placeholder='0' input={stakeAmount} />
                {disabledMin && <TextSM style={{color:'red'}}>Min {'>='} 44.5</TextSM>}
                <Tips>
                    <TextSM color='2'>Staking MDAO can earn both MDAO and MEER, and each staked MDAO can also receive one vote.</TextSM>
                </Tips>
                <Approve w={100} {...approve} />
            </Card>
            <WhiteSpace />
            <Card decorate='1'>
                <FlexBlock flex>
                    <div>
                        <TextSM color='2'>My Votes</TextSM>
                        <br />
                        <TextMD>{wFormatInt(balance)} <TextSM>/ MDAO</TextSM></TextMD>
                    </div>
                    <a href='https://snapshot.org/#/medinadao.eth' target='_blank'>
                        <Button size='0' >Vote</Button>
                    </a>
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                {   
                    position.length === 0 ? <NoData />:
                    position.map(v => (
                        <div style={{marginTop: '8px'}} key={v.id}>
                            <FlexBlock flex>
                                <TextSM color='2'>Staked MDAO</TextSM>
                                <TextSM>{v.staked}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Rewarded MDAO</TextSM>
                                <TextSM>{v.rewardMDAO}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Rewarded MEER</TextSM>
                                <TextSM>{v.rewardMeer}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Unlcok Days</TextSM>
                                <TextSM>{v.days}</TextSM>
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                        
                    ))
                }
            </Card>
            
        </Container>
    )
}

export default Govern

// [11:17, 21/05/2023] +971 58 554 5678: 需要显示的数据有
// 矿机单价。 
// 每t 多少油。  
// 日产出多少
// 每t  待提取收益 
// 当前算力有多少

// 以及显示直推矿机算力
// [11:17, 21/05/2023] +971 58 554 5678: //矿机比例  50% 1e5
//     uint public mineScale = 50000;
//     //每T算力单价 U本位 1e5
//     uint public minePrice = 600_00000;
//     //每T日产量
//     uint public dailyProduction = 10 ether;
// [11:17, 21/05/2023] +971 58 554 5678: meer矿机信息
// struct PowerInfo {
//     // 用户矿机算力
//     uint power;
//     //累计获取收益
//     uint totalRewardMeer;
//     //已提取收益
//     uint claimedMeer;
// }
// /// @notice meer矿机仓位
// struct MeerDeposit {
//     //算力 T
//     uint256 power;
//     // 开始时间
//     uint256 startTime;
//     // 开始时间
//     uint256 rewardTime;
//     // 已奖励的 meer
//     uint256 rewardMeer;
//     // 每T单价
//     uint256 price;
// }