import { memo } from 'react';
import styled from 'styled-components';
// import { useHistory } from "react-router-dom";
import { wFormatInt } from 'web3-sam/dist/BigNumber';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,
    NoData
} from '../../components'

// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'
import {
    useCompensation,
    useRegisterMet,
    useClaim,
    useSettleHistory,
    useRegisterMetPro,
    useRegisterMetMintPro,
    useClaimPro
} from '../../hook/pages/useCompensation'

import Approve from '../../contractComponents/Approve';

// import {useTransactionList} from '../../hook/useTransaction'

// import TransactionDetail from '../../pageComponents/TransactionDetail'

// // dialog 
// import useDialog from '../../hook/useDialog'

// import useCopy from '../../hook/useCopy'

// import {useTotalStaked, useClaim, useWithdraw, useNodeWithdraw} from '../../hook/pages/useStake'
// import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
// import {utils} from 'web3-sam'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// const { shortAddress } = utils

const CliamMemo = memo(() => {
    return <Button size='0' {...useClaim()} />
})

const CliamV1Memo = memo(() => {
    return <Button size='0' {...useClaimPro()} />
})

const BurnAllMemo = memo(() => {
    const {approve} = useRegisterMetPro();
    return <Approve w='49' {...approve} />
})

const MintHalfMemo = memo(() => {
    const {approve} = useRegisterMetMintPro();
    return <Approve w='49' status='1' {...approve} />
})

function CompensationV0() {
    const {
        claim,
        totalStaked,
        activatedMet,
        metAmount,
        rewardedValue
    } = useCompensation()

    return (
        <Card decorate='1'>
            <TextMD>Compensation V0</TextMD>
            <br />
            <TextSM color='2'>Compensation and met balance equal value of MDAO</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>Available</TextSM>
                    <br />
                    <TextMD>{claim} <TextSM color='2'>/ MDAO</TextSM></TextMD>
                    <br />
                    <TextSM color='2'>Rewarded Value <TextSM>{rewardedValue}</TextSM></TextSM>
                </div>
                <CliamMemo />
            </FlexBlock>
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>Activated MET</TextSM>
                <TextSM >{wFormatInt(activatedMet)}</TextSM>
            </FlexBlock>
        </Card>
    )
}

function CompensationV1() {
    const bal = useBalance()

    const {
        v1: {
            claim,
            totalStaked,
            activatedMet,
            metAmount,
            rewardedValue
        }
    } = useCompensation()

    return (
        <Card decorate='1'>
            <TextMD>Compensation Pro</TextMD>
            <br />
            <TextSM color='2'>Burn MET or Mint Half MSC</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>Available</TextSM>
                    <br />
                    <TextMD>{claim} <TextSM color='2'>/ MDAO</TextSM></TextMD>
                    <br />
                    <TextSM color='2'>Rewarded Value <TextSM>{rewardedValue}</TextSM></TextSM>
                </div>
                <CliamV1Memo />
            </FlexBlock>
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>Activated MET</TextSM>
                <TextSM >{wFormatInt(activatedMet)}</TextSM>
            </FlexBlock>
            <FlexBlock flex>
                <TextSM color='2'>MET Balance</TextSM>
                <TextSM >{wFormatInt(bal.balance.MET)}</TextSM>
            </FlexBlock>
            <div
                style={{
                    margin: '16px 0px',
                    padding: '8px',
                    background: 'rgba(255,255,255,.06)',
                    borderRadius: '8px',
                    color: '#aeafb9'
                }}
            >
                {/* <TextM>Burn All:</TextM>
                <TextSM color='2'>Burn All: Burning MET will result in a 100% compensation share.</TextSM> */}
                Burn All: Burning MET will result in a 100% compensation share.
                <WhiteSpace />
                Mint MSC: Burning MET will result in a 50% compensation share and 50% MSC.
                {/* <TextM>Mint MSC:</TextM>
                <TextSM color='2'></TextSM> */}
            </div>
            <FlexBlock flex>
                {
                    bal.balance.MET * 1 < 100 ?
                    <Button size='1' w='100' disabled>Insufficient MET balance </Button>:
                    <>
                        <BurnAllMemo />
                        <MintHalfMemo />
                    </>
                }
                
            </FlexBlock>
        </Card>
    )
}

function Compensation() {

    // const tx = useTransactionList()
    // console.log(bal, ' bal')
    return (
        <Container>
            
            {/* <WhiteSpace />
            <History /> */}
            {/* <History /> */}
            <CompensationV1 />
            <WhiteSpace />
            <CompensationV0 />
            {/* <History /> */}
        </Container>
    );
}

export default Compensation

// useSettleHistory

function History() {
    const {data} = useSettleHistory()
    // console.log(data, ' data')
    return (
        <Card decorate='1'>
            <TextM>Compensation History</TextM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            {   
                data.list ?
                data.list.map(v => (
                    <div
                        key={v.blockNumber}
                        style={{
                            marginBottom: '12px',
                        }}
                    >
                        <FlexBlock flex >
                            <TextSM color='2'>MDAO Amount</TextSM>
                            <TextSM>{v.mdaoAmount}</TextSM>
                        </FlexBlock>
                        <FlexBlock flex >
                            <TextSM color='2'>MDAO Value</TextSM>
                            <TextSM>{v.rewardValue}</TextSM>
                        </FlexBlock>
                        <FlexBlock flex >
                            <TextSM color='2'>Compensation Block</TextSM>
                            <TextSM>{v.blockNumber}</TextSM>
                        </FlexBlock>
                        <WhiteSpace />
                        <Hr />
                    </div>
                    
                )):
                <NoData />
            }
        </Card>
    )
}