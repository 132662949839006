// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    I360,
    TokenAddr,
    Govern,
    Nft360,
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"
import useButton from "./useButton"



const INIT = {
    claimMDAO: 0, // mdao available to claim
    claimMEER: 0, // meer available to claim
    totalStaked: 0, // mdao total staked
    balance: 0, // user staked mdao before unstake
    availableWithdraw: 0, // user available to withdraw mdao
    position: [],
    positionStaked: 0,
}


async function init(account) {
    const govern = Govern()

    // console.log(
    //     await govern.methods.claimFor(account).call(),
    // )

    const calls = await multiCalls({
        balance: govern.methods.balanceOf(account),
        claim: govern.methods.claimFor(account),
        totalStaked: govern.methods.totalSupply(),
    })

    const claimMDAO = BN(calls.claim.rewardMdao).div(1e18).dp(4,1).toString()
    const claimMEER = BN(calls.claim.rewardMeer).div(1e18).dp(4,1).toString()
    const balance = BN(calls.balance).div(1e18).toString(10)
    const totalStaked = BN(calls.totalStaked).div(1e18).toString(10)
    
    let positionStaked = BN(0)
    
    const now = Math.floor(Date.now() / 1000)
    const daysSeconds = 24 * 60 * 60
    const position = calls.claim.userPositions.map((item,i) => {
        const staked = BN(item.amount).div(1e18).toString(10)
        const rewardMDAO = BN(item.claimedReward).div(1e18).dp(4,1).toString(10)
        const rewardMeer = BN(item.claimedMEERReward).div(1e18).dp(4,1).toString(10)

        positionStaked = positionStaked.add(staked)

        return {
            staked,
            rewardMDAO,
            rewardMeer,
            days: Math.floor(180 - ((now - item.startTime)/ daysSeconds)),
            startTime: item.startTime,
            id: i
        }
    }).sort((a,b) => b.startTime - a.startTime)
    positionStaked = positionStaked.toString(10)
    const availableWithdraw = BN(balance).sub(positionStaked).toString(10)
    return {
        balance,
        claimMEER,
        claimMDAO,
        totalStaked,
        position,
        positionStaked,
        availableWithdraw
    }
}


export function useGovernConsole() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => init(account), setData)
    },[account, blockNubmer])
    return {
        ...data
    }
}


export function useGovernStake() {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Stake')

    const stakeAmount = useInput('', {type:'nubmer', placeholder: 'Stake Amount'})


    const stakeFun = () => {
        // 签名
        loading("Pending")
        const gov = Govern()
        const amount = BN(stakeAmount.value).mul(1e18).dp(0,1).toString(10)
        SendLocalOn(
            gov.methods.stake(amount),
            {
                seed: 3,
                signDone: init,
                cancel() {
                    fail('Stake Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Stake Successful')
                }
            }
        )
    }

    const {
        sender,
        coins
    } = useMemo(() => {
        const sender = Govern()._address
        // const 
        const coins = [[...TokenAddr().MDAO, 1000000, true]]
        return {
            sender,
            coins
        }
    },[])

    const disabledMin = stakeAmount.value !== '' && stakeAmount.value*1 < 44.5

    return {
        stakeAmount,
        disabledMin,
        approve: {
            sender,
            coins,
            then: stakeFun,
            loading: button.loading,
            children: button.children,
            disabled: disabledMin
        }
    }
}

export function useClaim() {
    const {account} = useWeb3()
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton('Claim')


    const claimFun = () => {
        
        // 签名
        loading("Pending")
        const gov = Govern()
        SendLocalOn(
            gov.methods.claimFor(account),
            {
                seed: 3,
                signDone: init,
                cancel() {
                    fail('Claim Cancel')
                },
                fail(err) {
                    txError(err.message)
                },
                confirm () {
                    successful('Claim successful')
                }
            }
        )
    }

    return {
        ...button,
        onClick: claimFun
    }
}


const INIT_360 = {
    totalStaked: 0, // mdao total staked
    staked: 0, // ndft
    claim: 0,
    stock: 0
}
// function totalStaked() external view returns(uint);
// function updateStock(address) external;
// function stake() external;
// function claimFor(address) external returns(uint);
// function stockOf(address) external view returns(StockInfo memory);
async function init360(account) {
    const i360 = I360()

    // console.log(
    //     await  i360.methods.stakedNFT(account).call(),
    //     await i360.methods.stockOf(account).call(),
    //     await i360.methods.perStockReward().call()
    // )

    const calls = await multiCalls({
        totalStaked: i360.methods.totalStaked(),
        staked: i360.methods.stakedNFT(account),
        claim: i360.methods.claimFor(account),
        stock: i360.methods.stockOf(account)
    })

    const totalStaked = calls.totalStaked
    const staked = calls.staked
    const claim = BN(calls.claim).div(1e18).dp(6,1).toString(10)
    const stock = calls.stock.stock
    
    return {
        totalStaked,
        staked,
        claim,
        stock
    }
}

export function use360() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT_360)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => init360(account), setData)
    },[account, blockNubmer])
    return {
        ...data
    }
}

export function use360Stake() {
    const {account} = useWeb3()

    const {
        sender,
        coins
    } = useMemo(() => {
        const sender = I360()._address
        const coins = [
            [Nft360()._address, '0', "365NFT", 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])
    

    const stakeButton = useButton('Stake', {
        approve: {
            sender,
            coins
        },
        send() {
            const node = I360()
            return node.methods.stake()
        }
    })

    const unstakeButton = useButton('Unstake', {
        send() {
            const node = I360()
            return node.methods.unstake()
        }
    })

    const claimButton = useButton('Claim', {
        send() {
            const node = I360()
            return node.methods.claimFor(account)
        }
    })

    return {
        stakeButton,
        unstakeButton,
        claimButton
    }
}