import { memo } from 'react';

import { useHistory } from "react-router-dom";

import styled from 'styled-components';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip
} from '../../components'

// import StakeDialog from '../StakeDialog'

// import useBalance from '../../hook/pages/useBalance'


// dialog 
// import useDialog from '../../hook/useDialog'

// import useCopy from '../../hook/useCopy'

import {
    use360,
    use360Stake,
} from '../../hook/pages/useGovern'

// import {useTotalStaked, useClaim, useWithdraw, useNodeWithdraw} from '../../hook/pages/useStake'
// import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
// import {utils} from 'web3-sam'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import PurchaseGNFT from './PurchaseGNFT'

// import {useSmiCountdown} from '../../hook/useCountdown'

import Approve from '../../contractComponents/Approve';


function Nft360() {
    const {
        // totalStaked,
        staked,
        claim,
        stock
    } = use360()
    const {
        stakeButton,
        unstakeButton,
        claimButton
    } = use360Stake()
    return (
        <Card decorate='1'>
            <TextMD>365 NFT</TextMD>
            <br />
            <TextSM color='2'>365 NFT Revenue Capture Pool</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>Available USDT</TextSM>
                    <br />
                    <TextMD>{claim}</TextMD>
                </div>
                <Button size='0' {...claimButton} />
            </FlexBlock>
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>Staked NFT <TextSM>{staked}</TextSM></TextSM>
                    <br />
                    <TextSM color='2'>My Stock <TextSM>{stock}</TextSM></TextSM>
                </div>
                {
                    staked * 1 > 0 ?
                    <Button size='0' status='3' {...unstakeButton}/> :
                    <Approve size='0' status='1' {...stakeButton}/>
                }
            </FlexBlock>
        </Card>
    )
}

export default Nft360;