
import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,
    ChooseButtons
} from '../../components'

import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'


// dialog 
import useDialog from '../../hook/useDialog'

import useCopy from '../../hook/useCopy'

import {
    usePurchase
} from '../../hook/pages/useGNFT'
import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

import Approve from '../../contractComponents/Approve'
import styled from 'styled-components'

// import {useSmiCountdown} from '../../hook/useCountdown'

// const { shortAddress } = utils
const PURCHASE_TYPE = [
    {id: 1, title: 'USDT'},
    {id: 2, title: 'MDAO'},
    // {id: 3, title: 'M+U'}

]

function Purchase() {
    // pAmount,
    //     approve
    const bal = useBalance()
    // console.log()
    const {
        nftType,
        pTpye,
        pAmount,
        needAmount,
        approve
    } = usePurchase(bal.getPrice(["MDAO", "USDT"]))
    // const buyed = mAmount.value * bal.getPrice(["MDAO", "USDT"])
    return (
        <Card>
            <TextMD>Purchase NFT</TextMD>
            <WhiteSpace />
            <TextSM>Available <TextLinear size='0'>{wFormatInt(bal.balance.USDT)}</TextLinear> USDT · <TextLinear size='0'>{wFormatInt(bal.balance.MDAO)}</TextLinear> MDAO</TextSM>
            <WhiteSpace />
            <Input
                after={
                    <NftName
                        onClick={() => {
                            nftType.onChange(nftType.value === "GNFT" ? "365NFT" : "GNFT")
                        }}
                    >{nftType.value}</NftName>
                }
                input={{
                    ...pAmount,
                    // onKeyDown: (e) => {
                    //     e.preventDefault()
                    //     console.log(e.target.value)
                    // },
                    onChange: (e) => {
                        pAmount.onChange(Math.floor(e.target.value.replace('.','')*1))
                    }
                }}
            />
            {/* {
                pTpye.value === 1 ?
                <>
                    <TextSM>Available <TextLinear size='0'>{wFormatInt(bal.balance.USDT)}</TextLinear> USDT</TextSM>
                    <WhiteSpace />
                    <Input input={pAmount}/>
                </>:
                <>
                    <TextSM>Available <TextLinear size='0'>{wFormatInt(bal.balance.MDAO)}</TextLinear> MDAO</TextSM>
                    <br />
                    <Input input={mAmount}/>
                    <br />
                    <TextSM>Expected {wFormatInt(buyed)} USDT Through MDAO</TextSM>
                </>
            } */}
            <WhiteSpace />
            {
                nftType.value === "GNFT" ?
                <TextSM>Need {wFormatInt(needAmount[pTpye.value])} {pTpye.value*1 === 1 ? 'USDT':'MDAO'}</TextSM>:
                <TextSM>Need {wFormatInt(needAmount[1])} USDT</TextSM>
            }
            {
                            console.log(nftType.value, nftType.value === "GNFT")}
            {
                nftType.value === "GNFT" && 
                <>
                    <WhiteSpace />
                    <ChooseButtons
                        size='0'
                        list={PURCHASE_TYPE}
                        {...pTpye}
                    />
                </>
            }
            
            <WhiteSpace />
            <Approve w='100' {...approve} />
        </Card>
    )
}

export default Purchase


const NftName = styled(TextSM)`
    padding: 4px;
    background: #f1f1f4;
    color: #34353c;
    border-radius: 6px;
    font-weight: 800;
`