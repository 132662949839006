// 记录本地交易
// 本地地址的交易记录存储到本地数据库中
/**
 * 基本接口
 * 1. 添加交易记录
 * 1.1 改变交易状态
 * 2. 读取交易
 * 3. 清除交易
 * 4. 更新单笔交易状态
 * 5. 检查 pending 交易
 */

// transfer detail
// {
//     creatTime: number,
//     confirmTime: number,
//     to: string,
//     summary: {
//         customize
//     }
//     receipt: {}
// }

// trasnfer list
// {
//     hash:transfer,
// }

// receipt 交易状态 null 为 pending , {} end | status true 为成功， false 为失败
// summary 增加 conent 字段，显示提示 
// 取消 error
const INIT_TRANSFER = {
    creatTime: 0,
    confirmTime: 0,
    to: null,
    summary: null,
    receipt: null
}

function isPending(tx) {
    return !tx.receipt
}
// init transfer
// @dev 获取所有交易记录 且封装接口
export function SendStorage(chainId, fromAddr) {
    // 只能存储字符串，对象会被转译
    const localKey = `transfer_${chainId}_${fromAddr}`;
    const _cache = JSON.parse(window.localStorage.getItem(localKey) || '{}');

    const newEvent = SetEvent()

    // 添加交易记录
    function setTx({hash, receipt, summary, to, creatTime = 0, confirmTime = 0}) {
        _cache[hash] = {
            hash,
            creatTime,
            confirmTime,
            to,
            summary,
            receipt
        }
        updateTx(hash)
    }

    // 获取交易记录
    function getTx(hash) {
        if ( !_cache[hash] ) {
            _cache[hash] = {...INIT_TRANSFER}
        }
        return _cache[hash]
    }

    // get pending transfer
    function getPendingTx() {
        return Object.values(_cache).filter(v => isPending(v))
    }

    // get pending transfer hash
    function getPendingTxHash() {
        return Object.keys(_cache).filter(k => isPending(_cache[k]))
    }

    // get transfer list
    function getTxFilter(filterCallback= () => true) {
        return Object.values(_cache).filter(v => filterCallback(v))
    }

    // update local transfer
    function updateTx() {
        window.localStorage[localKey] = JSON.stringify(_cache);
        newEvent.emit()
    }

    // clear local transfer
    function clearTx() {
        window.localStorage[localKey] = '{}'
        newEvent.emit({})
    }

    //// 二次封装，交易流程
    // 创建交易
    function createTx({hash, to, summary}) {
        // 抛出异常
        if (!to || !summary) throw new Error('createTx: to or summary is null')
        setTx({
            hash,
            to,
            summary,
            creatTime: Math.floor(Date.now() / 1000)
        })
    }

    // 交易确认
    function confirmTx({hash, receipt = null}) {
        if (!_cache[hash]) throw new Error('confirmTx: transfer is not exist')
        _cache[hash].receipt = receipt
        _cache[hash].confirmTime = Math.floor(Date.now() / 1000)
        updateTx(hash)
    }

    function getAll () {
        return _cache
    }

    return {
        setTx,
        getTx,
        getPendingTx,
        getPendingTxHash,
        updateTx,
        clearTx,
        createTx,
        confirmTx,
        remove: newEvent.remove,
        on: newEvent.on,
        getAll,
        getTxFilter
    }
}


///////// 使用 Set 类型封装 事件 /////////
export function SetEvent() {
    const _event = new Set()
    function on(event) {
        _event.add(event)
    }

    function remove(event) {
        _event.delete(event)
    }

    function clear() {
        _event.clear()
    }

    function getAll() {
        return _event
    }

    // 执行事件
    function emit(...params) {
        _event.forEach(event => event instanceof Function && event(...params))
    }

    return {
        on,
        remove,
        clear,
        getAll,
        emit
    }
}


//// single modle 处理全局账户，需要使用其他的 local 则重新创建 SendStorage
let _key = null
let _TxStorage = null
export function InitSendStorage(chainId, fromAddr) {
    const key = `${chainId}_${fromAddr}`
    if (_key !== key) {
        _TxStorage = SendStorage(chainId, fromAddr)
        _key = key
    }
}

export default function GetTxStorage() {
    return _TxStorage
}