export const inPc = (() => {
    var sUserAgent = navigator.userAgent;
    if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
        return false
    } else {
        return true
    }
})();

function linearGradient(deg, start, stop) {
    return `linear-gradient(${deg}deg,  ${start}, ${stop})`
}

// function shadow(color) {
//     return `0px 0px .4rem ${color}`
// }


// card
// block input
// text
// button
// alert
// draw

// size
// background
// border

const background = [
    {
        // #7fc248,#63bb35
        background: linearGradient(180, 'rgba(64, 66, 74, 0.95)','rgba(42, 43, 51, 0.95)')
        // background: "url(./gameUI/button.png)"
    },
    {
        background: 'radial-gradient(circle at left top,rgba(103, 64, 177,0.3),rgba(0,0,0,0) 30.71%),radial-gradient(circle at right bottom,rgba(46, 63, 110, 0.2) 3.71%,rgba(0,255,0,0) 48.71%), rgba(37, 38, 43, 0.96)'
    },
    {
        background: 'radial-gradient(circle at left top,rgba(188,51,104,0.3),rgba(0,0,0,0) 30.71%),radial-gradient(circle at right bottom,rgb(116 19 49 / 20%) 3.71%,rgba(0,255,0,0) 48.71%), rgba(37, 38, 43, 0.96)'
    },
    {
        
        backgroundImage: `linear-gradient(146deg, rgba(28,153,246, 0.95) 0%, rgba(143,59,225, 0.95) 51%, rgba(92,39,221, 0.95) 97%, rgba(92,39,221, 0.95) 97%)`
    },
    {
        background: 'linear-gradient(146deg, rgba(7,219,169, 0.95) 0%, rgba(70,121,233, 0.95) 51%, rgba(126,62,183, 0.95) 100%)'
    },
    {
        background: 'linear-gradient(136deg,#ddb15c,#d00000)'
    },
    {
        background: `linear-gradient(to right,#212329,#2a2b33),linear-gradient(136deg,#dd5c61,#3354ff)`,
        border: '0.1rem solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box'
    },
    {
        background: `linear-gradient(136deg,#dd5c61,#3354ff)`,
        border: '0.1rem solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box'
    }
]

const color = [
    '#f1f1f4',
    // '#f0af4b',
    '#688eb6',
    '#aeafb9',
    '#838383',
    '#54575a',
    '#353941',
    '#000'
]

const size = new Array(7).fill(1).map((v,i) => v * 1.382**i);

const theme = {
    // public
    MAX_WIDTH: 1080,
    isPc: inPc,
    isV2: window.location.hostname.split(".")[0] !== 'v3',
    body: '#212329',
    tableBackground: '#f2f2f2',
    footerHeight: '5.2rem',
    borderRadius: '12px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
    active: '#F3503C',
    testLinear: `background: ${linearGradient(120, '#f7e68d', '#f5c576')}`,
    // font
    size,
    padding: size.map(v => `${v * 0.618}rem ${v}rem`),
    // 由亮 到 暗
    // 字体颜色
    color,
    buttonSize: [
        // small
        {
            fontSize: '1rem',
            // borderRadius: '0rem',
        },
        {
            fontSize: '1.4rem',
            padding: '1rem 1.4rem',
        },
        {
            fontSize: '1.8rem',
            padding: '1.4rem 1.8rem',
        },
        {
            fontSize: '2.6rem',
            padding: '1.4rem 1.8rem',
        },
        {
            fontSize: '3rem',
            padding: '1.4rem 1.8rem',
        },
    ],
    // 由 亮 变 暗
    block: [
        background[0],
        background[1],
        background[2],
        background[3],
        background[4],
        background[5],
        background[6],
        background[7],
    ],
    draw: background[2],
    // 由 亮 变 暗
    button: [
        // normal
        {
            ...background[4],
            color: color[0]
        },
        // normalA
        {
            ...background[5],
            color: color[0]
        },
        // normalB
        {
            ...background[3],
            color: color[0]
        },
        // border
        {
            ...background[6],
            color: color[0]
        },
        // disabled
        {
            background: '#353434',
            color: '#cbcbcb !important',
            border: '1px solid #565656'
        },
        // active
        {
            ...background[7],
            color: color[0]
        },
        // un active
        {
            ...background[6],
            color: color[0]
        },
        // ...other
    ],
    squareDot: [
        background[8],
        background[9]
    ]
}

export default theme