
// 过滤 PROXY_ZERO_ADDRESS 地址请求
// 切换参数过快时，请求顺序无法确定，导致 第一次请求覆盖第二次请求 bug
import { initWeb3, getBlock, ZERO_ADDRESS } from '../web3'

async function initDate(asyncFun, setFun) {
    const blockNubmer = getBlock.getNewBlock()
    const web3 = initWeb3()
    // 初始地址 和 blockNubmer 不加载，减少请求次数
    if (web3.currentProvider.selectedAddress === ZERO_ADDRESS || blockNubmer === 0 ) return
    setFun(await asyncFun())
}

export default initDate

