import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip
} from '../../components'

import { useSign } from '../../hook/pages/useStake'
import { useDialog } from '../../hook'

function Protocol () {
    const { close } = useDialog()
    const {
        button,
        signFun
    } = useSign()
    return (
        // <Container>
            
            <Card>
                <TextMD>MedinaDAO 2.0 Liquidity Stake Code Rules</TextMD>
                <WhiteSpace />
                <TextM>Please review and agree to the rules below before joining the pool.</TextM>
                <WhiteSpace />
                <TextSM>1. Cryptocurrency fundamentally has high price fluctuations and risks. Be fully aware of this and be cautious about investing.</TextSM>
                <WhiteSpace />
                <TextSM>2. The price of MDAO and all other tokens traded in the MedinaDAO ecosystem is formed by autonomous trading in the market, and the MedinaDAO team does not intervene and is not responsible.</TextSM>
                <WhiteSpace />
                <TextSM>3. MedinaDAO All liquidity pools cannot use fiat currencies and only use cryptocurrency-based Usdt and other cryptocurrencies.</TextSM>
                <WhiteSpace />
                <TextSM>4. MedinaDAO All liquidity pools are operated by the MDAO Ecosystem, and the investment principal is not guaranteed and is non-refundable.</TextSM>
                <WhiteSpace />
                <TextSM>5. Contractual Relations - Staking on any product in the Medina DAO ecosystem is your contract in the smart contract and has nothing to do with the referrer, and related disputes have no legal effect.</TextSM>
                <WhiteSpace />
                <TextSM>6. When participating in all liquidity-related products of MedinaDAO, the operation of a multi-layered profit structure or hybrid structure using the anonymity of the block chain is strictly prohibited.</TextSM>
                <WhiteSpace />
                <TextSM>7. Blockchain cannot be counterfeited and tampered with and offers the advantages of transparency and irreversibility.  All actions, including freezing of accounts, can be taken through the blacklist system for all actions that disrupt the ecosystem, such as illegal multi-layered structures.</TextSM>
                <WhiteSpace />
                <TextSM>8.Participation in the Medina DAO Glory pool is based on one account per person, and accounts exceeding this limit are recognized as a multi-layered structure and can be registered on the blacklist.</TextSM>
                <WhiteSpace />
                <TextSM>9. DeFi products developed in the MedinaDAO ecosystem have various safety devices implemented to prepare for sudden emergencies, and can be controlled by the technical team without DAO resolution to protect the system.</TextSM>
                <WhiteSpace />
                <TextSM>10. The DeFi project has no institutional protection and is solely based on the analysis and judgment of the investor, so you are responsible for all risks.</TextSM>
                <WhiteSpace />
                <TextSM>11. The investor is responsible for all possible risks related to the investment, and civil and criminal liability cannot be demanded from anyone related to the recommendation, including the MedinaDAO operation team. </TextSM>
                <WhiteSpace />
                <TextSM>I fully agree to the above terms and conditions.</TextSM>
                <WhiteSpace />
                <Button w='100' status='2' {...button} onClick={signFun} />
                <WhiteSpace />
                <Button w='100' status='3' onClick={close}>Cancel</Button>
            </Card>
        // </Container>
        
    )
}

export default Protocol


