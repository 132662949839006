
import React, { useMemo, useState } from 'react'
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    TextGL,
    TextMD,
    TextSM,
    TextXL,
    WhiteSpace,
    FlexBlock,
    FlexBlockAuto,
    Block,
    Button,
    Hr,
    Container,
    Tooltip,
    Icon,
    TextM,
    TabStyled,
    HrCol,
    Hot,
    APR,
    TextLinear,
    Fragment,
    Polyline,
    Coin,
    TextP,
    Text,
    HrDotted,
    SquareDot,
    // CardBox,
    Input
} from "../../components"; 

function ChooseToken() {
    return (
        <Card decorate='1'>
            <TextM>Select a Token</TextM>
            <WhiteSpace />
            <Input
                placeholder="Search token"
            />
        </Card>
    )
}

export default ChooseToken